import { Spin } from 'antd';
import { Navigate, useParams } from 'react-router-dom';
import { useGetCrmBaseDataQuery } from '../api/membership-api';

export const CheckSecurityCode = () => {
    let params = useParams();

    const { data, error, isLoading } = useGetCrmBaseDataQuery(params.securityCode!);

    return (
        <div style={{ padding: '10px' }}>
            {isLoading ? (
                <Spin tip="Loading" size="large">
                    <div className="content" />
                </Spin>
            ) : null}
            {error ? <Navigate to="/" replace={true} state={{ error: true }} /> : null}
            {data ? <Navigate to={`/app/${params.securityCode!}`} state={data} replace={true} /> : null}
        </div>
    );
};
