import { Form, Input, Select } from 'antd';
import { ClinicType } from '../../../../models';

const { Option } = Select;

type CompanyInformationProps = {
    disabled: boolean;
    selectedCompanyType: ClinicType;
};

export const CompanyInformation = ({ disabled, selectedCompanyType }: CompanyInformationProps) => {
    const isHospital = selectedCompanyType === ClinicType.Spital;

    return (
        <>
            <Form.Item name="companyType" label="Praxis-/Firmentyp">
                <Select disabled={disabled}>
                    <Option value={ClinicType.Einzelpraxis}>Einzelpraxis</Option>
                    <Option value={ClinicType.Gruppenpraxis}>Gruppenpraxis</Option>
                    <Option value={ClinicType.Institut}>Institut</Option>
                    <Option value={ClinicType.Poliklinik}>Poliklinik</Option>
                    <Option value={ClinicType.Spital}>Spital</Option>
                    <Option value={ClinicType.Andere}>Andere</Option>
                </Select>
            </Form.Item>
            <Form.Item name="companyName" label="Praxisname oder Spital">
                <Input disabled={disabled} />
            </Form.Item>

            {isHospital ? (
                <Form.Item name="companyNameAddition" label="Klinik">
                    <Input disabled={disabled} />
                </Form.Item>
            ) : null}

            <Form.Item name="street" label="Strasse">
                <Input disabled={disabled} />
            </Form.Item>
            <Form.Item name="zipcode" label="Postleitzahl">
                <Input disabled={disabled} />
            </Form.Item>
            <Form.Item name="city" label="Ort">
                <Input disabled={disabled} />
            </Form.Item>
            <Form.Item name="telephone" label="Telefonnummer Praxis/Klinik">
                <Input disabled={disabled} />
            </Form.Item>
        </>
    );
};
