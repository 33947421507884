import React, { useState } from 'react';
import { DatePicker, Form, Select, Switch } from 'antd';
import { dateFormats, facharztTitelOptionset } from '../../../constants';
import { AusgestelltIn } from '../../../models';
import { FormInstance } from 'antd/lib/form/Form';

const { Option } = Select;

const codeOhneFacharzttitel = 915240029;

type FacharztTitelProps = {
    showByDefault?: boolean;

    initialToggle?: boolean;
    required?: boolean;
    issuedIn: AusgestelltIn;
    facharztTitelCodeWatcher: number;
    facharztTitelCode: string | undefined | number;
    count: number;
    form: FormInstance<any>;
};

export const FacharztTitel = ({
    showByDefault = true,
    initialToggle,
    required,
    issuedIn,
    facharztTitelCodeWatcher,
    facharztTitelCode,
    count,
    form,
}: FacharztTitelProps) => {
    const notDirty = facharztTitelCode === facharztTitelCodeWatcher;

    const [toggle, setToggle] = useState<boolean | undefined>(notDirty || initialToggle);

    const name = `facharztTitel${count}Code`;
    const label = count === 1 ? `Facharzttitel 1 - Haupttätigkeit` : `Facharzttitel ${count}`;
    const issuedInName = `issuedIn${count}`;
    const approvedByMebekoOnName = `approvedByMebekoOn${count}`;

    const switchLabel = `${label} eintragen`;

    const hasFachArztTitel = facharztTitelCodeWatcher !== codeOhneFacharzttitel;
    const facharztTitelHasChanged = facharztTitelCodeWatcher !== facharztTitelCode;
    const toolTip =
        'Falls Sie zwei oder mehr Facharzttitel besitzen, tragen Sie hier bitte den Titel ein, auf dem Sie hauptsächlich arbeiten. Ein Arzt mit den Titeln Allgemeine Innere Medizin (AIM) und Kardiologie, der v.a. als Kardiologe praktiziert, trägt hier Kardiologie ein und notiert AIM als Facharzttitel 2';
    return (
        <>
            {!showByDefault && !notDirty ? (
                <Form.Item label={switchLabel}>
                    <Switch
                        checked={toggle}
                        onChange={(val) => {
                            if (!val) form.setFieldValue(name, null);
                            setToggle(val);
                        }}
                    />
                </Form.Item>
            ) : null}

            {showByDefault || toggle ? (
                <>
                    <Form.Item
                        tooltip={count === 1 ? toolTip : null}
                        label={label}
                        name={name}
                        rules={[{ required: required }]}
                    >
                        <Select>
                            {facharztTitelOptionset.map((optionSet) => (
                                <Option key={optionSet.key} value={optionSet.key}>
                                    {optionSet.value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {hasFachArztTitel && facharztTitelHasChanged ? (
                        <>
                            <Form.Item rules={[{ required: true }]} label="ausgestellt in" name={issuedInName}>
                                <Select>
                                    <Option value={AusgestelltIn.AusgestelltInSwiss}>Schweiz</Option>
                                    <Option value={AusgestelltIn.AusgestelltInAusland}>Ausland</Option>
                                </Select>
                            </Form.Item>
                            {issuedIn === AusgestelltIn.AusgestelltInAusland ? (
                                <Form.Item
                                    name={approvedByMebekoOnName}
                                    dependencies={['issuedIn1', 'issuedIn2', 'issuedIn3', 'issuedIn4']}
                                    rules={[{ required: true }]}
                                    label="anerkannt durch MEBEKO am"
                                    tooltip="Für ausländische Facharzttitel ist eine Anerkennungsbestätigung der Medizinalberufekommission MEBEKO notwendig:
                                            Bundesamt für Gesundheit
                                            MEBEKO
                                            3003 Bern
                                            Tel. 058 462 94 83
                                            E-Mail: mebeko@bag.admin.ch"
                                >
                                    <DatePicker format={dateFormats} />
                                </Form.Item>
                            ) : null}
                        </>
                    ) : null}
                </>
            ) : null}
        </>
    );
};
