import { Form, Select } from 'antd';
import { BabApprovalType } from '../../../models';

const { Option } = Select;

type BabTypeProps = {
    onChange: (babType: any) => void;
    name: string;
    label: string;
    disabled: boolean;
};

export const BabType = ({ onChange, label, name, disabled }: BabTypeProps) => {
    return (
        <Form.Item rules={[{ required: true }]} label={label} name={name}>
            <Select onChange={onChange} disabled={disabled}>
                <Option key={BabApprovalType.babFor10Years} value={BabApprovalType.babFor10Years}>
                    ordentliche Bewilligung zur fachlich eigenverantwortlichen Tätigkeit (BAB für 10 Jahre)
                </Option>
                <Option key={BabApprovalType.babFromAge70Years} value={BabApprovalType.babFromAge70Years}>
                    BAB ab 70. Altersjahr (BAB für max. 3 Jahre)
                </Option>
                <Option key={BabApprovalType.babFor90Days} value={BabApprovalType.babFor90Days}>
                    90-Tage-Bewilligung
                </Option>
                <Option key={BabApprovalType.noBab} value={BabApprovalType.noBab}>
                    keine BAB
                </Option>
            </Select>
        </Form.Item>
    );
};
