import { Button, Skeleton } from 'antd';
import { BaseStepProps } from '../StepBase';
import { useGetCrmMembershipWorkLocationDataQuery } from '../../../api/membership-api';
import { useParams } from 'react-router-dom';
import { NavigationButton } from '../NavigationButton';
import { useState } from 'react';
import { WorkingLocations } from './WorkingLocations';
import { WorkingLocationCreateForm } from './WorkingLocationCreateForm';

export const ArbeitsorteStep = ({ setStep }: BaseStepProps) => {
    let params = useParams();

    const [openCreateForm, setOpenCreateForm] = useState(false);

    const {
        data: memberShipData,
        error: membershipDataLoadingError,
        isFetching: isLoadingMembershipData,
    } = useGetCrmMembershipWorkLocationDataQuery(params.securityCode!);

    const { babType, workLocations, id } = memberShipData || {};

    const isBusy = isLoadingMembershipData;

    if (isBusy) return <Skeleton active />;

    const hasValidLocations = workLocations?.filter((location) => !location.to);

    return (
        <>
            {openCreateForm ? (
                <WorkingLocationCreateForm
                    membershipAppId={id!}
                    babType={babType}
                    open={openCreateForm}
                    onCreate={() => setOpenCreateForm(false)}
                    onCancel={() => setOpenCreateForm(false)}
                />
            ) : null}

            <h2>Arbeitsorte</h2>

            <WorkingLocations membershipAppId={id!} babType={babType} locations={workLocations || []} />
            <Button
                onClick={() => {
                    setOpenCreateForm(true);
                }}
                style={{ marginBottom: '10px' }}
                disabled={hasValidLocations && hasValidLocations.length >= 4}
                type="primary"
            >
                Arbeitsort hinzufügen
            </Button>
            <NavigationButton
                onNext={async () => {
                    setStep(4);
                }}
                onBack={() => setStep(2)}
                showSaveButton={false}
            />
        </>
    );
};
