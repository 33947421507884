export type BaseStepProps = {
    setStep: React.Dispatch<React.SetStateAction<number>>;
};

/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
    required: 'Ist ein Pflichtfeld',
    types: {
        email: 'keine gültige Email',
    },
};
/* eslint-disable no-template-curly-in-string */

export const layout = {
    labelCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        lg: { span: 4 },
        xl: { span: 4 },
        xxl: { span: 3 },
    },
    wrapperCol: {
        xs: { span: 24, offset: 1, pull: 1 },
        sm: { span: 24, offset: 1, pull: 1 },
        lg: { span: 12 },
        xl: { span: 12, offset: 0 },
        xxl: { span: 6, offset: 1 },
    },
};
