import { Form, Select } from 'antd';
import { facharztTitelOptionset } from '../../../constants';
import { OkpApprovalfaCode } from '../../../models';

const { Option } = Select;

type OkpApprovalProps = {
    count: number;
    facharztTitelKey: string;
};

export const OkpApproval = ({ facharztTitelKey, count }: OkpApprovalProps) => {
    const facharztLabel = facharztTitelOptionset.find((x) => x.key === Number(facharztTitelKey))?.value;

    const label = (
        <span>
            OKP-Zulassung zu <b>{facharztLabel}</b>
        </span>
    );
    const name = `okpApprovalfa${count}Code`;

    return (
        <>
            <Form.Item name={name} label={label}>
                <Select allowClear placeholder="--Auswählen--">
                    <Option value={OkpApprovalfaCode.selfEmployed}>als selbständiger Leistungserbringer</Option>
                    <Option value={OkpApprovalfaCode.employed}>im Rahmen eines Anstellungsverhältnisses</Option>
                    <Option value={OkpApprovalfaCode.selfEmplyedAndEmployed}>selbständig und angestellt</Option>
                    <Option value={OkpApprovalfaCode.noApproval}>keine Zulassung</Option>
                </Select>
            </Form.Item>
        </>
    );
};
