import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import deDe from 'antd/lib/locale/de_DE';
import { BrowserRouter } from 'react-router-dom';
import { store } from './api/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ConfigProvider
                    locale={deDe}
                    theme={{
                        components: {
                            Form: {
                                colorTextLabel: '#63554b',
                            },
                        },
                        token: {
                            colorPrimary: '#0090df',
                            colorText: '#63554b',
                            fontFamily: 'Open Sans,Helvetica Neue,Helvetica,sans-serif',
                            fontWeightStrong: 500,
                        },
                    }}
                >
                    <App />
                </ConfigProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
);
