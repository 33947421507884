import { Col, Checkbox, Form, Row } from 'antd';
import { WorkingType } from '../../../models';

type WorkingStateProps = {
    currentWorkingTypes: WorkingType[];
    disabled: boolean;
};

export const WorkingState = ({ currentWorkingTypes, disabled }: WorkingStateProps) => {
    const isNotActive = currentWorkingTypes?.includes(WorkingType.notActive);
    return (
        <Form.Item
            rules={[{ required: true }]}
            name="workingtypes"
            label="Ich bin / werde demnächst wie folgt ärztlich tätig"
        >
            <Checkbox.Group>
                <Row>
                    <Col span={24}>
                        <Checkbox value={WorkingType.selfEmployed} disabled={isNotActive}>
                            Als selbständige/r Arzt/Ärztin (Inhaber oder Teilhaber einer Praxis)
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value={WorkingType.employed} disabled={isNotActive}>
                            Als angestellte/r Arzt/Ärztin
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value={WorkingType.notActive}>
                            Bin / werde zurzeit nicht aktiv ärztlich tätig
                        </Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </Form.Item>
    );
};
