import { Form, Input, Select } from 'antd';
import { WorkingType, ZsrNotRequiredReason, ZsrRequiredOption } from '../../../models';
const { Option } = Select;

type ZsrNumberProps = {
    showZsrNumber: boolean;
    workingType: WorkingType[];
    zsrOption: ZsrRequiredOption;
};

export const ZsrNumber = ({ showZsrNumber, workingType, zsrOption }: ZsrNumberProps) => {
    const showZsrReasonOptions =
        workingType.find((x) => x === WorkingType.selfEmployed) && zsrOption === ZsrRequiredOption.noZsrRequired;
    const showZsrReasonText =
        (workingType.includes(WorkingType.employed) || workingType.includes(WorkingType.notActive)) &&
        !workingType.includes(WorkingType.selfEmployed) &&
        zsrOption === ZsrRequiredOption.required;
    return (
        <>
            <Form.Item
                rules={[{ required: true }]}
                name="zsrOption"
                tooltip="Um als selbständige/r Arzt/Ärztin in eigener Praxis Ihre Leistungen zu Lasten der Versicherungen (OKP-Leistungen) in Rechnung stellen zu können, benötigen Sie eine ZSR-Nummer der SASIS"
                label="Benötigen Sie eine persönliche ZSR-Nummer?"
            >
                <Select>
                    <Option value={ZsrRequiredOption.required}>ja, ZSR-Nr. erwünscht</Option>
                    <Option value={ZsrRequiredOption.alreadyExist}>nein, ZSR.-Nr. bereits vorhanden</Option>
                    <Option value={ZsrRequiredOption.noZsrRequired}>nein, keine persönliche ZSR-Nr. nötig</Option>
                </Select>
            </Form.Item>
            {showZsrReasonText ? (
                <Form.Item
                    rules={[{ required: true }]}
                    name="zsrRequiredReasonAsEmployeed"
                    label="Ohne Tätigkeit als selbständige/r Arzt/Ärztin brauchen Sie keine ZSR-Nr. Warum möchten Sie eine ZSR-Nummer lösen?"
                >
                    <Input />
                </Form.Item>
            ) : null}
            {showZsrReasonOptions ? (
                <Form.Item
                    rules={[{ required: true }]}
                    name="zsrNotRequiredReason"
                    label="Aus welchem Grund benötigen Sie keine Abrechnungsnummer?"
                >
                    <Select>
                        <Option value={ZsrNotRequiredReason.onlyPrivateInvoicingPlanned}>
                            Keine Zulassung zur OKP erhalten, nur Privatrechnungen geplant
                        </Option>
                        <Option value={ZsrNotRequiredReason.movedToForeignCountry}>
                            Kein Zulassung zur OKP beantragt, in den Ausstand getreten
                        </Option>
                        <Option value={ZsrNotRequiredReason.GDZurichNotDecidedYet}>
                            Zulassungsgesuch bei GD Zürich gestellt, Entscheid ausstehend
                        </Option>
                    </Select>
                </Form.Item>
            ) : null}

            {showZsrNumber ? (
                <>
                    <Form.Item rules={[{ required: true }]} name="zsr1Id" label="ZSR-Nr. 1">
                        <Input />
                    </Form.Item>
                    <Form.Item name="zsr2Id" label="ZSR-Nr. 2">
                        <Input />
                    </Form.Item>
                </>
            ) : null}
        </>
    );
};
