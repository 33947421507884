import { Form, message, Skeleton } from 'antd';
import { BaseStepProps, validateMessages } from '../StepBase';
import { NavigationButton } from '../NavigationButton';
import {
    useGetCountriesQuery,
    useGetCrmMembershipContactDataQuery,
    useUpdateCrmMembershipContactDataMutation,
} from '../../../api/membership-api';
import { useParams } from 'react-router-dom';
import { InvoiceAddress } from './InvoiceAddress';
import { PersonalContactData } from './PersonalContactData';
import { PrivateAddress } from './PrivateAddress';
import { ZazDeliveryAddress } from './ZazDeliveryAddress';
import { DeliveryAgzNewsletter } from './DeliveryAgzNewsletter';
import { AgzNewsletterType } from '../../../models';

export const layout = {
    labelCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        lg: { span: 12 },
        xl: { span: 7 },
        xxl: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24, offset: 1, pull: 1 },
        sm: { span: 24, offset: 1, pull: 1 },
        lg: { span: 12 },
        xl: { span: 12, offset: 0 },
        xxl: { span: 10, offset: 1 },
    },
};

export const KontaktDatenStep = ({ setStep }: BaseStepProps) => {
    const [form] = Form.useForm();
    let params = useParams();

    const [updateMembershipData, { isLoading: isUpdating }] = useUpdateCrmMembershipContactDataMutation();

    const invoiceAddressTypeWatch = Form.useWatch('invoiceAddressType', form);
    const zazAddressTypeWatch = Form.useWatch('zazDeliveryAddressType', form);
    const privateMailWatch = Form.useWatch('privateMail', form);
    const businessMailWatch = Form.useWatch('businessMail', form);
    const agzNewsletterTypeWatch = Form.useWatch('agzNewsletterType', form);

    const privateAddressStreetWatch = Form.useWatch('privateAddressStreet', form);
    const privateAddressZipCodeWatch = Form.useWatch('privateAddressZipCode', form);
    const privateAddressCityWatch = Form.useWatch('privateAddressCity', form);
    const privateAddressCountryIdWatch = Form.useWatch('privateAddressCountryId', form);

    const {
        data: memberShipData,
        error: membershipDataLoadingError,
        isLoading: isLoadingMembershipData,
    } = useGetCrmMembershipContactDataQuery(params.securityCode!);

    const { data: countries, error: countriesLoadingError, isLoading: isLoadingCountries } = useGetCountriesQuery('');

    const {
        id,
        privateAddressAddition1,
        privateAddressAddition2,
        privateAddressStreet,
        privateAddressZipCode,
        privateAddressCity,
        privateAddressCountryId,
        businessPhone,
        privatePhone,
        mobilePhone,
        privateMail,
        businessMail,
        invoiceAddressType,
        zazDeliveryAddressType,
        agzNewsletterType,
        hasArbeitsort1Values,
        hasArbeitsort2Values,
        hasArbeitsort3Values,
        hasArbeitsort4Values,
    } = memberShipData || {};

    const onFinish = async (values: any) => {
        await updateMembershipData({ ...values, id });
        message.success('Daten aktualisiert', 3);
    };

    const isBusy = isLoadingCountries || isLoadingMembershipData;

    const privateMailHasValue = !!privateMailWatch;
    const businessMailHasValue = !!businessMailWatch;
    const privateAddressHasValues =
        privateAddressStreetWatch &&
        privateAddressZipCodeWatch &&
        privateAddressCityWatch &&
        privateAddressCountryIdWatch;

    if (isBusy) return <Skeleton active />;

    return (
        <>
            <Form
                validateTrigger="invoiceAddressType"
                colon={false}
                labelWrap={true}
                labelAlign="left"
                size="large"
                form={form}
                disabled={isUpdating}
                {...layout}
                name="contact"
                onFinish={(v) => onFinish(v)}
                validateMessages={validateMessages}
                initialValues={{
                    privateAddressAddition1,
                    privateAddressAddition2,
                    privateAddressStreet,
                    privateAddressZipCode,
                    privateAddressCity,
                    privateAddressCountryId,
                    businessPhone,
                    privatePhone,
                    mobilePhone,
                    privateMail,
                    businessMail,
                    invoiceAddressType,
                    zazDeliveryAddressType,
                    agzNewsletterType: agzNewsletterType === AgzNewsletterType.nein ? null : agzNewsletterType,
                }}
            >
                <h2>Persönliche und private Kontaktdaten</h2>

                <PrivateAddress
                    zazInvoiceAddressType={zazAddressTypeWatch}
                    invoiceAddressType={invoiceAddressTypeWatch}
                    countries={countries}
                />
                <PersonalContactData
                    privateMailHasValue={privateMailHasValue}
                    businessMailHasValue={businessMailHasValue}
                />
                <InvoiceAddress
                    arbeitsort1HasValues={hasArbeitsort1Values || false}
                    arbeitsort2HasValues={hasArbeitsort2Values || false}
                    arbeitsort3HasValues={hasArbeitsort3Values || false}
                    arbeitsort4HasValues={hasArbeitsort4Values || false}
                    privateAddressHasValues={privateAddressHasValues}
                    invoiceAddressType={invoiceAddressTypeWatch}
                />

                <ZazDeliveryAddress
                    privateAddressHasValues={privateAddressHasValues}
                    zazInvoiceAddressType={zazAddressTypeWatch}
                />

                <DeliveryAgzNewsletter
                    newsletterType={agzNewsletterTypeWatch}
                    privateMailHasValue={privateMailHasValue}
                    businessMailHasValue={businessMailHasValue}
                />

                <NavigationButton
                    onNext={async () => {
                        await form.validateFields();
                        const values = form.getFieldsValue();
                        await onFinish(values);
                        setStep(5);
                    }}
                    onBack={() => setStep(3)}
                    showSaveButton={true}
                />
            </Form>
        </>
    );
};
