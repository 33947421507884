import { ClinicType, JobRole } from '../../../models';

export const CompanyRoleMapping = [
    {
        type: [ClinicType.Einzelpraxis, ClinicType.Gruppenpraxis],
        role: [JobRole.SelbstaendigerArzt, JobRole.AngestellterArzt, JobRole.AssistenzarztInPraxis, JobRole.Sonstige],
    },
    {
        type: [ClinicType.Institut, ClinicType.Poliklinik],
        role: [JobRole.Institutsleiter, JobRole.AngestellterArzt, JobRole.AssistenzarztInPraxis, JobRole.Sonstige],
    },
    {
        type: [ClinicType.Spital],
        role: [JobRole.LeitenderSpitalarzt, JobRole.Spitalfacharzt, JobRole.Sonstige],
    },
    {
        type: [ClinicType.Andere],
        role: [
            JobRole.SelbstaendigerArzt,
            JobRole.AngestellterArzt,
            JobRole.Institutsleiter,
            JobRole.AssistenzarztInPraxis,
            JobRole.LeitenderSpitalarzt,
            JobRole.Spitalfacharzt,
            JobRole.Sonstige,
        ],
    },
];
