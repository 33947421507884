import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CheckSecurityCode } from './pages/CheckSecurityCode';
import { SecurityCodeLogin } from './pages/SecurityCodeLogin';
import { MembershipApp } from './pages/MembershipApp';
import { PageLayout } from './pages/PageLayout';
import { SuccessPageAfterFinish } from './pages/SuccessPageAfterFinish';

const App = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<PageLayout />}>
                    <Route index element={<SecurityCodeLogin />} />
                    <Route path="app/complete" element={<SuccessPageAfterFinish />} />
                    <Route path="check/:securityCode" element={<CheckSecurityCode />} />
                    <Route path="app/:securityCode" element={<MembershipApp />} />
                    <Route path="*" element={<SecurityCodeLogin />} />
                </Route>
            </Routes>
        </div>
    );
};

export default App;
