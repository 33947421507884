import { Col } from 'antd';
import { useState } from 'react';
import { FileType, Document } from '../../../models';
import { UploadDocument } from './UploadDocument';

type TarmedContractProps = {
    membershipId: string;
    crmDocuments: Document[] | undefined;
};

export const TarmedContract = ({ membershipId, crmDocuments }: TarmedContractProps) => {
    const [isTarmedUploaded, setIsTarmedUploaded] = useState<boolean>();

    return (
        <>
            <p>
                Bitte treten Sie dafür den TARMED-Verträgen bei. Die Verträge finden Sie hier als PDF-Formulare{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://aerzte-zh.ch/pdf/Informationen/mitglied/Beitrittserkl%C3%A4rungen_TARMED_AGZ_FMH_2020.pdf"
                >
                    Beitrittserklärungen TARMED AGZ
                </a>
                <br />
                Drucken Sie die Formulare aus, tragen Sie Ihre Angaben ein und unterzeichnen Sie auf Seite 1 (kantonaler
                Anschlussvertrag AGZ) einmal sowie auf Seite 2 (Schweizerischer Rahmenvertrag FMH) zweimal.
            </p>
            <UploadDocument
                membershipId={membershipId!}
                name="tarmedContract"
                label="Bitte laden Sie Ihre vollständigen TARMED-Verträge als PDF hoch!"
                fileType={FileType.Tarmed}
                files={crmDocuments}
                onUploaded={setIsTarmedUploaded}
            />
            {isTarmedUploaded ? (
                <Col xl={13}>
                    <p style={{ padding: '10px', backgroundColor: '#e6f4ff' }}>
                        Nach Eingang Ihres vollständigen Aufnahmeantrags inkl. der unterschriebenen Tarmed-Verträge
                        erhalten Sie von der AGZ eine Pro-forma-Mitgliedschaftsbestätigung bzw.
                        Tarmed-Beitrittsbestätigung, welche Sie Ihrem Antrag auf eine ZSR-Nummer an die SASIS AG
                        beilegen.
                        <br />
                        Nähere Informationen und Unterlagen für die Beantragung Ihrer Zahlstellenregister-Nummer finden
                        Sie bei der SASIS AG.{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.sasis.ch/de/Entry/ProductEintrag/ProductMenuEintrag?eintragId=10538&selectedMenuId=1005&secondLevelMenuId=-1"
                        >
                            Link
                        </a>
                        <br />
                        <br />
                        Kontaktadresse: <br />
                        SASIS AG
                        <br />
                        Bahnhofstrasse 7<br />
                        Postfach 6002
                        <br />
                        Luzern
                        <br />
                        Telefon 032 625 42 43
                        <br />
                        E-Mail: zsr@sasis.ch
                        <br />
                    </p>
                </Col>
            ) : null}
        </>
    );
};
