/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Input, Radio, Select, InputNumber, DatePicker, FormInstance } from 'antd';
import { BabApprovalType, ClinicType, JobRole, JobRoleEnumLabel, WorkingType, WorkLocation } from '../../../models';
import { WorkingLocationEditForm } from './WorkingLocationEditForm/WorkingLocationEditForm';
import { useState } from 'react';
import { BabInfo } from './BabInfo';
import { CompanyRoleMapping } from './CompanyRoleMapping';
import { dateFormats } from '../../../constants';
import dayjs from 'dayjs';

const { Option } = Select;

type WorkingLocationProps = {
    location: WorkLocation;
    count: number;
    babType: BabApprovalType | undefined;
    occupations?: WorkingType[];
    form: FormInstance;
    membershipAppId: string;
};

export const WorkingLocation = ({ location, count, babType, form, membershipAppId }: WorkingLocationProps) => {
    const [openEditForm, setOpenEditForm] = useState(false);
    const [stayAtWorkLocation, setStayAtWorkLocation] = useState(false);

    const companyTypeName = `companyType${count}`;
    const jobRoleName = `jobRole${count}`;

    const companyTypeWatch = Form.useWatch<ClinicType>(companyTypeName, form);
    const jobRoleWatch = Form.useWatch<JobRole>(jobRoleName, form);

    const companyNameLabel = `companyName${count}`;
    const companyNameAdditionLabel = `companyNameAddition${count}`;
    const workingHereName = `workingHere${count}`;
    const streetName = `street${count}`;
    const zipCodeName = `zipcode${count}`;
    const cityName = `city${count}`;
    const employmentPercentageName = `employmentPercentage${count}`;
    const takeOverClinicTelephoneName = `takeOverClinicTelephone${count}`;
    const takeOverClinicFromNameLabel = `takeOverClinicFrom${count}`;
    const takeOverClinicEmailName = `takeOverClinicEmailName${count}`;
    const toName = `to${count}`;
    const fromName = `from${count}`;

    const companyJobRoles = CompanyRoleMapping.find((c) => c.type.includes(companyTypeWatch))?.role;
    const showBabInfo = babType !== BabApprovalType.noBab && jobRoleWatch === JobRole.AssistenzarztInPraxis;
    const {
        companyType,
        companyName,
        companyNameAddition,
        street,
        zipcode,
        city,
        to,
        jobRole,
        employmentPercentage,
        from,
        takeOverClinicEmail,
        takeOverClinicFromName,
        takeOverClinicTelephone,
    } = location;
    const countLabel = count + 1;

    const isHospital = companyType === ClinicType.Spital;

    return (
        <>
            {openEditForm ? (
                <WorkingLocationEditForm
                    stayAtWorkLocation={stayAtWorkLocation}
                    babType={babType}
                    open={openEditForm}
                    membershipAppId={membershipAppId}
                    onCreate={() => setOpenEditForm(false)}
                    onCancel={() => setOpenEditForm(false)}
                    location={location}
                />
            ) : null}

            <h3>{countLabel}. Arbeitsort </h3>

            <Form.Item
                rules={[{ required: true }]}
                label="Ich bin / bleibe an diesem Arbeitsort tätig"
                name={workingHereName}
                valuePropName="value"
                initialValue={null}
            >
                <Radio.Group
                    onChange={({ target }) => {
                        if (!target.checked) return;
                        setStayAtWorkLocation(target.value);
                        setOpenEditForm(true);
                    }}
                >
                    <Radio value={true}>Ja</Radio>
                    <Radio value={false}>Nein</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item initialValue={companyType} name={companyTypeName} label="Praxis-/Firmentyp">
                <Select disabled>
                    <Option value={ClinicType.Einzelpraxis}>Einzelpraxis</Option>
                    <Option value={ClinicType.Gruppenpraxis}>Gruppenpraxis</Option>
                    <Option value={ClinicType.Institut}>Institut</Option>
                    <Option value={ClinicType.Poliklinik}>Poliklinik</Option>
                    <Option value={ClinicType.Spital}>Spital</Option>
                    <Option value={ClinicType.Andere}>Andere</Option>
                </Select>
            </Form.Item>
            <Form.Item initialValue={companyName} name={companyNameLabel} label="Praxisname oder Spital">
                <Input disabled />
            </Form.Item>
            {isHospital ? (
                <Form.Item initialValue={companyNameAddition} name={companyNameAdditionLabel} label="Klinik">
                    <Input disabled />
                </Form.Item>
            ) : null}

            <Form.Item initialValue={street} name={streetName} label="Strasse">
                <Input disabled />
            </Form.Item>
            <Form.Item initialValue={zipcode} name={zipCodeName} label="Postleitzahl">
                <Input disabled />
            </Form.Item>
            <Form.Item initialValue={city} name={cityName} label="Ort">
                <Input disabled />
            </Form.Item>

            {companyTypeWatch ? (
                <>
                    {showBabInfo ? <BabInfo /> : null}
                    <Form.Item initialValue={jobRole} name={jobRoleName} label="Rolle">
                        <Select disabled>
                            {companyJobRoles?.map((role) => {
                                if (!companyTypeWatch) return null;
                                return (
                                    <Option value={role} key={role}>
                                        {JobRoleEnumLabel.filter((x) => x.key === role)[0]?.displayValue}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </>
            ) : null}

            <Form.Item initialValue={employmentPercentage} name={employmentPercentageName} label="Stellenprozent">
                <InputNumber disabled min={1} max={100} />
            </Form.Item>
            <Form.Item initialValue={from ? dayjs(from) : null} name={fromName} label="In dieser Rolle ab">
                <DatePicker disabled format={dateFormats} />
            </Form.Item>

            <Form.Item initialValue={to ? dayjs(to) : null} name={toName} label="Hier tätig bis">
                <DatePicker disabled format={dateFormats} />
            </Form.Item>
            {takeOverClinicFromName ? (
                <Form.Item
                    initialValue={takeOverClinicFromName}
                    label="Ich übernehme die Praxis / Praxisanteile von"
                    name={takeOverClinicFromNameLabel}
                >
                    <Input disabled />
                </Form.Item>
            ) : null}
            {takeOverClinicTelephone ? (
                <Form.Item
                    initialValue={takeOverClinicTelephone}
                    label="Telefonnummer Praxis / Klinik"
                    name={takeOverClinicTelephoneName}
                >
                    <Input disabled />
                </Form.Item>
            ) : null}
            {takeOverClinicEmail ? (
                <Form.Item
                    initialValue={takeOverClinicEmail}
                    label="E-Mail Praxis / Klinik"
                    name={takeOverClinicEmailName}
                >
                    <Input disabled />
                </Form.Item>
            ) : null}
        </>
    );
};
