import { Alert, Form, Select } from 'antd';
import { ZazDeliveryAddressType } from '../../../models';

const { Option } = Select;

type PrivateAddressProps = {
    zazInvoiceAddressType: ZazDeliveryAddressType;
    privateAddressHasValues: boolean;
};

export const ZazDeliveryAddress = ({ zazInvoiceAddressType, privateAddressHasValues }: PrivateAddressProps) => {
    const isPrivateAddressSelected = zazInvoiceAddressType === ZazDeliveryAddressType.privateAddress;

    return (
        <>
            <h3>Zustellung «Zürcher Ärztezeitung»</h3>
            <p>
                Die «Zürcher Ärztezeitung» ist das Publikationsorgan der vereinsrechtlich organisierten
                Standesorganisation AGZ, in dem alle Beschlüsse der Delegiertenversammlung der AGZ erscheinen.
                <br /> Die Zeitschrift erscheint 4-mal pro Jahr und wird den Mitgliedern kostenlos zugestellt
            </p>
            {isPrivateAddressSelected && !privateAddressHasValues ? (
                <Alert
                    banner
                    style={{ marginBottom: '15px' }}
                    message="Bitte geben Sie oben Ihre Privatadresse an"
                    type="info"
                    showIcon
                />
            ) : null}
            <Form.Item rules={[{ required: true }]} label="Gewünschte Zustelladresse" name="zazDeliveryAddressType">
                <Select>
                    <Option value={ZazDeliveryAddressType.privateAddress}>Privatadresse</Option>
                    <Option value={ZazDeliveryAddressType.invoiceAddress}>Rechnungs- und Korrespondenzadresse</Option>
                </Select>
            </Form.Item>
        </>
    );
};
