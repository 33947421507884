import { Form, Row, Col, Button } from 'antd';

type NavigationButtonType = {
    onNext?: () => void;
    nextButtonLabel?: string;
    onBack?: () => void;
    showSaveButton?: boolean;
    disableSaving?: boolean;
};

export const NavigationButton = ({
    onNext,
    onBack,
    showSaveButton,
    nextButtonLabel,
    disableSaving,
}: NavigationButtonType) => {
    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            {showSaveButton ? (
                <Col>
                    <Form.Item>
                        <Button disabled={disableSaving} type="primary" htmlType="submit">
                            Speichern
                        </Button>
                    </Form.Item>
                </Col>
            ) : null}

            {onBack ? (
                <Col>
                    <Form.Item>
                        <Button onClick={onBack}>Zurück</Button>
                    </Form.Item>
                </Col>
            ) : null}
            {onNext ? (
                <Col>
                    <Form.Item>
                        <Button disabled={disableSaving} onClick={onNext}>
                            Weiter
                        </Button>
                    </Form.Item>
                </Col>
            ) : null}
        </Row>
    );
};
