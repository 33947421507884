export const dateFormats = ['DD.MM.YYYY', 'DD.M.YYYY', 'D.M.YYYY', 'DD.MM.YY', 'DD.M.YY', 'D.M.YY'];

export const facharztTitelOptionset = [
    { key: 915240029, value: 'ohne Facharzttitel' },
    { key: 915240001, value: 'Allergologie und klinische Immunologie' },
    { key: 915240002, value: 'Allgemeine Innere Medizin' },
    { key: 915240003, value: 'Anästhesiologie' },
    { key: 915240004, value: 'Angiologie' },
    { key: 915240005, value: 'Arbeitsmedizin' },
    { key: 915240006, value: 'Chirurgie' },
    { key: 915240007, value: 'Dermatologie und Venerologie' },
    { key: 915240008, value: 'Endokrinologie / Diabetologie' },
    { key: 915240009, value: 'Gaströnterologie' },
    { key: 915240010, value: 'Gefässchirurgie' },
    { key: 915240011, value: 'Gynäkologie und Geburtshilfe' },
    { key: 915240012, value: 'Hämatologie' },
    { key: 915240013, value: 'Handchirurgie' },
    { key: 915240014, value: 'Herz- und thorakale Gefässchirurgie' },
    { key: 915240015, value: 'Infektiologie' },
    { key: 915240016, value: 'Intensivmedizin' },
    { key: 915240017, value: 'Kardiologie' },
    { key: 915240018, value: 'Kinder- und Jugendmedizin' },
    { key: 915240019, value: 'Kinder- und Jugendpsychiatrie und -psychotherapie' },
    { key: 915240020, value: 'Kinderchirurgie' },
    { key: 915240021, value: 'Klinische Pharmakologie und Toxikologie' },
    { key: 915240022, value: 'Medizinische Genetik' },
    { key: 915240023, value: 'Medizinische Onkologie' },
    { key: 915240024, value: 'Mund-, Kiefer und Gesichtschirurgie' },
    { key: 915240025, value: 'Nephrologie' },
    { key: 915240026, value: 'Neurochirurgie' },
    { key: 915240027, value: 'Neurologie' },
    { key: 915240028, value: 'Nuklearmedizin' },
    { key: 915240030, value: 'Ophthalmologie' },
    { key: 915240031, value: 'Orthopädische Chirurgie und Traumatologie des Bewegungsapparates' },
    { key: 915240032, value: 'Oto-Rhino-Laryngologie' },
    { key: 915240033, value: 'Pathologie' },
    { key: 915240034, value: 'Pharmazeutische Medizin' },
    { key: 915240035, value: 'Physikalische Medizin und Rehabilitation' },
    { key: 915240036, value: 'Plastische, Rekonstruktive und Ästhetische Chirurgie' },
    { key: 915240037, value: 'Pneumologie' },
    { key: 915240039, value: 'Prävention und Gesundheitswesen' },
    { key: 915240038, value: 'Praktischer Arzt / Ärztin' },
    { key: 915240040, value: 'Psychiatrie und Psychotherapie' },
    { key: 915240041, value: 'Radiologie' },
    { key: 915240042, value: 'Radio-Onkologie / Strahlentherapie' },
    { key: 915240043, value: 'Rechtsmedizin' },
    { key: 915240044, value: 'Rheumatologie' },
    { key: 915240045, value: 'Thoraxchirurgie' },
    { key: 915240046, value: 'Tropen- und Reisemedizin' },
    { key: 915240047, value: 'Urologie' },
];
