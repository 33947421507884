import { DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useCreateNewWorkLocationMutation } from '../../../api/membership-api';
import { dateFormats } from '../../../constants';
import { BabApprovalType, ClinicType, JobRole, JobRoleEnumLabel } from '../../../models';
import { BabInfo } from './BabInfo';
import { CompanyRoleMapping } from './CompanyRoleMapping';

const { Option } = Select;

type WorkingLocationCreateFormProps = {
    open: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
    babType: BabApprovalType | undefined;
    membershipAppId: string;
};
export const WorkingLocationCreateForm = ({
    open,
    onCreate,
    onCancel,
    babType,
    membershipAppId,
}: WorkingLocationCreateFormProps) => {
    const [form] = Form.useForm();

    const [createNewLocation, { isLoading }] = useCreateNewWorkLocationMutation();

    const companyTypeWatch = Form.useWatch<ClinicType>('companyType', form);
    const jobRoleWatch = Form.useWatch<JobRole>('jobRole', form);

    const companyJobRoles = CompanyRoleMapping.find((c) => c.type.includes(companyTypeWatch))?.role;

    const showBabInfo = babType !== BabApprovalType.noBab && jobRoleWatch === JobRole.AssistenzarztInPraxis;
    const isSelfEmployed = jobRoleWatch === JobRole.SelbstaendigerArzt;

    const onFinish = (values: any) => {
        createNewLocation({ id: membershipAppId, ...values });
        onCreate(values);
    };
    const isHospital = companyTypeWatch === ClinicType.Spital;
    return (
        <Modal
            open={open}
            destroyOnClose={true}
            title={<h3>Arbeitsort hinzufügen</h3>}
            okText="Speichern"
            cancelText="Abbrechen"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.submit();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="vertical" name="workingLocationForm" onFinish={onFinish}>
                <Form.Item rules={[{ required: true }]} name="companyType" label="Praxis-/Firmentyp">
                    <Select>
                        <Option value={ClinicType.Einzelpraxis}>Einzelpraxis</Option>
                        <Option value={ClinicType.Gruppenpraxis}>Gruppenpraxis</Option>
                        <Option value={ClinicType.Institut}>Institut</Option>
                        <Option value={ClinicType.Poliklinik}>Poliklinik</Option>
                        <Option value={ClinicType.Spital}>Spital</Option>
                        <Option value={ClinicType.Andere}>Andere</Option>
                    </Select>
                </Form.Item>
                <Form.Item rules={[{ required: true }]} name="companyName" label="Praxisname oder Spital">
                    <Input />
                </Form.Item>

                {isHospital ? (
                    <Form.Item rules={[{ required: true }]} name="companyNameAddition" label="Klinik">
                        <Input />
                    </Form.Item>
                ) : null}

                <Form.Item rules={[{ required: true }]} name="street" label="Strasse">
                    <Input />
                </Form.Item>
                <Form.Item rules={[{ required: true }]} name="zipcode" label="Postleitzahl">
                    <Input />
                </Form.Item>
                <Form.Item rules={[{ required: true }]} name="city" label="Ort">
                    <Input />
                </Form.Item>

                {companyTypeWatch ? (
                    <>
                        {showBabInfo ? <BabInfo /> : null}
                        <Form.Item rules={[{ required: true }]} name="jobRole" label="Rolle">
                            <Select>
                                {companyJobRoles?.map((role) => {
                                    if (!companyTypeWatch) return null;
                                    return (
                                        <Option value={role} key={role}>
                                            {JobRoleEnumLabel.filter((x) => x.key === role)[0]?.displayValue}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </>
                ) : null}

                <Form.Item rules={[{ required: isSelfEmployed }]} name="employmentPercentage" label="Stellenprozent">
                    <InputNumber min={1} max={100} />
                </Form.Item>
                <Form.Item rules={[{ required: isSelfEmployed }]} name="from" label="In dieser Rolle ab">
                    <DatePicker format={dateFormats} />
                </Form.Item>

                {(companyTypeWatch === ClinicType.Einzelpraxis || companyTypeWatch === ClinicType.Gruppenpraxis) &&
                jobRoleWatch === JobRole.SelbstaendigerArzt ? (
                    <>
                        <Form.Item label="Ich übernehme die Praxis / Praxisanteile von" name="takeOverClinicFromName">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Telefonnummer Praxis / Klinik" name="takeOverClinicTelephone">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            rules={[{ type: 'email' }]}
                            label="E-Mail Praxis / Klinik"
                            name="takeOverClinicEmail"
                        >
                            <Input />
                        </Form.Item>
                    </>
                ) : null}
            </Form>
        </Modal>
    );
};
