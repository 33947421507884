import { Checkbox, Form, Input, message, Radio, Select, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import {
    useGetCrmMembershipMitgliedschaftenDataQuery,
    useUpdateCrmMembershipMitgliedschaftenDataMutation,
} from '../../../api/membership-api';
import { NavigationButton } from '../NavigationButton';
import { BaseStepProps, validateMessages } from '../StepBase';

const { Option } = Select;

export const layout = {
    labelCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        lg: { span: 24, offset: 0 },
        xl: { span: 24 },
        xxl: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24, offset: 1, pull: 1 },
        sm: { span: 6, offset: 1, pull: 1 },
        lg: { span: 6, offset: 1, pull: 1 },
        xl: { span: 6, offset: 1, pull: 1 },
        xxl: { span: 6, offset: 1 },
    },
};

export const MitgliedschaftenStep = ({ setStep }: BaseStepProps) => {
    const [form] = Form.useForm();
    let params = useParams();

    const fmhMemberWatch = Form.useWatch('fmhMember', form);
    const noMemberBgWatch = Form.useWatch('noMemberBg', form);

    const [updateMembershipData, { isLoading: isUpdating }] = useUpdateCrmMembershipMitgliedschaftenDataMutation();

    const {
        data: memberShipData,
        error: membershipDataLoadingError,
        isLoading: isLoadingMembershipData,
    } = useGetCrmMembershipMitgliedschaftenDataQuery(params.securityCode!);

    const {
        id,
        noMemberBg,
        vsaoMember,
        combiMemberVsao,
        vlssMember,
        fmhMember,
        fmhMembershipNr,
        isHospitalLeadDoctor,
        isLeadDoctor,
        isHospitalDoctor,
    } = memberShipData || {};

    const onFinish = async (values: any) => {
        await updateMembershipData({ ...values, id });
        message.success('Daten aktualisiert', 2.5);
    };

    const isBusy = isLoadingMembershipData;

    if (isBusy) return <Skeleton active />;

    return (
        <>
            <Form
                colon={false}
                labelWrap={true}
                labelAlign="left"
                form={form}
                disabled={isUpdating}
                {...layout}
                name="basic"
                size="large"
                onFinish={(v) => onFinish(v)}
                validateMessages={validateMessages}
                initialValues={{
                    noMemberBg,
                    vsaoMember,
                    combiMemberVsao,
                    vlssMember,
                    fmhMember,
                    fmhMembershipNr,
                }}
            >
                <h2>Mitgliedschaften</h2>
                {!isHospitalDoctor ? (
                    <>
                        <h3>Bezirksgesellschaften der AGZ</h3>
                        <p>Innerhalb der AGZ bestehen sechs regionale Sektionen, die Bezirksgesellschaften:</p>
                        <ul>
                            <li>
                                Ärzteverband der Bezirke Zürich und Dietikon (ZüriMed);{' '}
                                <a target="_blank" rel="noreferrer" href="http://www.zuerimed.ch">
                                    www.zuerimed.ch
                                </a>
                            </li>
                            <li>
                                Ärztegesellschaft des Zürcher Unterlandes (AZUL);{' '}
                                <a target="_blank" rel="noreferrer" href="http://www.azul-zh.ch">
                                    www.azul-zh.ch
                                </a>
                            </li>
                            <li>
                                Ärztegesellschaft der Bezirke Winterthur und Andelfingen (AWA);{' '}
                                <a target="_blank" rel="noreferrer" href="http://www.awanet.ch">
                                    www.awanet.ch
                                </a>
                            </li>
                            <li>
                                Gesellschaft der Ärzte des Zürcher Oberlandes (AGZO);
                                <a target="_blank" rel="noreferrer" href="http://www.agzo.ch">
                                    www.agzo.ch
                                </a>
                            </li>
                            <li>
                                Gesellschaft der Ärzte am Zürichsee (GAZ);{' '}
                                <a target="_blank" rel="noreferrer" href="http://www.gaz-zh.ch">
                                    www.gaz-zh.ch
                                </a>
                            </li>
                            <li>
                                Ärztegesellschaft des Bezirks Affoltern (AGBA);{' '}
                                <a target="_blank" rel="noreferrer" href="http://www.albisdocs.ch">
                                    www.albisdocs.ch
                                </a>
                            </li>
                        </ul>
                        <p>
                            Selbständig oder angestellt in ambulanter Praxis tätige Mitglieder der AGZ werden
                            automatisch Mitglieder der Bezirksgesellschaft ihres Praxis- bzw. Arbeitsortes (Art. 29
                            Ziffer 1 der Statuten der AGZ).
                            <br /> Die Bezirksgesellschaften haben die Funktion, den ärztlichen Notfalldienst zu
                            organisieren. Weitere Informationen zum Thema Notfalldienst finden Sie auf unserer Website{' '}
                            <a target="_blank" rel="noreferrer" href="http://www.aerzte-zh.ch">
                                www.aerzte-zh.ch
                            </a>
                            .<br /> Der Nichtbeitritt in die zuständige Bezirksgesellschaft entbindet nicht von der
                            gesetzlichen Notfalldienstpflicht.
                        </p>
                        <Form.Item
                            valuePropName="checked"
                            name="noMemberBg"
                            label={
                                <span>
                                    Ich <b>wünsche keinen Beitritt</b> in die zuständige Bezirksgesellschaft
                                </span>
                            }
                        >
                            <Checkbox
                                style={{ borderRadius: '25px', border: '3px solid grey', padding: '5px' }}
                                onChange={(e) => form.setFieldValue('bgEmailApproval', !e.target.checked)}
                            />
                        </Form.Item>
                        <span>
                            Wir bitten Sie aus administrativen Gründen um Ihr Einverständnis für die Weitergabe der
                            E-Mail-Adresse an die Bezirksgesellschaft.
                        </span>
                        {!noMemberBgWatch ? (
                            <Form.Item
                                valuePropName="checked"
                                name="bgEmailApproval"
                                rules={[{ required: true }]}
                                label={
                                    <span style={{ fontWeight: 'bolder' }}>
                                        Die AGZ darf meine E-Mail-Adresse an die zuständige Bezirksgesellschaft
                                        weiterleiten
                                    </span>
                                }
                            >
                                <Select placeholder="--Auswählen--" allowClear>
                                    <Option value={true}>Ja</Option>
                                    <Option value={false}>Nein</Option>
                                </Select>
                            </Form.Item>
                        ) : null}
                    </>
                ) : null}

                {!isLeadDoctor ? (
                    <>
                        <h3>Verband Schweizerischer Assistenz- und Oberärztinnen und -ärzte VSAO</h3>
                        <Form.Item
                            rules={[{ required: true }]}
                            name="vsaoMember"
                            label="Sind Sie aktives Mitglied des Verbands Schweizerischer Assistenz- und Oberärztinnen und -ärzte VSAO?"
                        >
                            <Radio.Group>
                                <Radio value={true}>Ja</Radio>
                                <Radio value={false}>Nein</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <p>
                            Der VSAO Zürich und die AGZ planen für angestellte Ärzte in ambulanten Praxen / Instituten
                            im Laufe des Jahres 2023 eine Kombimitgliedschaft AGZ + VSAO Zürich anzubieten.
                            <br /> Kombimitglieder zahlen der AGZ denselben Mitgliederbeitrag wie alle angestellte/n
                            Ärztinnen und Ärzte; der VSAO Zürich übernimmt die Arbeits-Rechtsberatung der
                            Kombimitglieder und erhält dafür einen Teil des AGZ-Mitgliederbeitrags. <br />
                            Nach Austritt aus dem VSAO Schweiz werden keine weiteren Beiträge an den VSAO fällig.
                        </p>
                        <Form.Item
                            name="combiMemberVsao"
                            label="Möchten Sie Kombimitglied AGZ / VSAO Zürich werden, sobald dies möglich wird?"
                        >
                            <Radio.Group>
                                <Radio value={true}>Ja</Radio>
                                <Radio value={false}>Nein</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>
                ) : null}
                {isHospitalLeadDoctor ? (
                    <>
                        <h3>Verein der Leitenden Spitalärzte der Schweiz VLSS</h3>
                        <Form.Item
                            rules={[{ required: true }]}
                            name="vlssMember"
                            label="Sind Sie aktives Mitglied des Vereins der Leitenden Spitalärzte der Schweiz VLSS?"
                        >
                            <Radio.Group>
                                <Radio value={true}>Ja</Radio>
                                <Radio value={false}>Nein</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>
                ) : null}

                <h3>Dachverband der Schweizer Ärzte und Ärztinnen FMH</h3>
                <p>
                    Ärztinnen und Ärzte können der FMH nicht direkt beitreten, sondern tun dies via eine
                    Basisorganisation.
                    <br /> Die AGZ ist die kantonale Basisorganisation der FMH im Kanton Zürich. Mit Ihrem Beitritt zur
                    AGZ werden Sie auch Mitglied des Dachverbandes FMH.
                    <br /> Personen, die Mitglied mehrerer Basisorganisationen sind, schulden den FMH-Mitgliedsbeitrag
                    selbstverständlich nur einmal.
                </p>
                <br />
                <Form.Item
                    rules={[{ required: true }]}
                    name="fmhMember"
                    label="Sind oder waren Sie bereits Mitglied des Dachverbandes der Schweizer Ärzte und Ärztinnen FMH?"
                >
                    <Radio.Group>
                        <Radio value={true}>Ja</Radio>
                        <Radio value={false}>Nein</Radio>
                    </Radio.Group>
                </Form.Item>
                {fmhMemberWatch ? (
                    <Form.Item name="fmhMembershipNr" label="Bitte geben Sie Ihre persönliche FMH-Mitgliedernummer an">
                        <Input />
                    </Form.Item>
                ) : null}

                <NavigationButton
                    onNext={async () => {
                        await form.validateFields();
                        const values = form.getFieldsValue();
                        await onFinish(values);
                        setStep(6);
                    }}
                    onBack={() => setStep(4)}
                    showSaveButton={true}
                />
            </Form>
        </>
    );
};
