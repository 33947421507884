import { Form, Input } from 'antd';

export const TakeOverFields = () => {
    return (
        <>
            <Form.Item label="Ich übernehme die Praxis / Praxisanteile von" name="takeOverClinicFromName">
                <Input />
            </Form.Item>
            <Form.Item label="Telefonnummer Praxis / Klinik" name="takeOverClinicTelephone">
                <Input />
            </Form.Item>
            <Form.Item rules={[{ type: 'email' }]} label="E-Mail Praxis / Klinik" name="takeOverClinicEmail">
                <Input />
            </Form.Item>
        </>
    );
};
