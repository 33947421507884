import { Form } from 'antd';
import { BabApprovalType, WorkingType, WorkLocation } from '../../../models';
import { WorkingLocation } from './WorkingLocation';

export const layout = {
    labelCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        lg: { span: 4 },
        xl: { span: 4 },
        xxl: { span: 3 },
    },
    wrapperCol: {
        xs: { span: 24, offset: 1, pull: 1 },
        sm: { span: 24, offset: 1, pull: 1 },
        lg: { span: 12 },
        xl: { span: 12, offset: 0 },
        xxl: { span: 6, offset: 1 },
    },
};

type WorkingLocationsProps = {
    locations: WorkLocation[];
    babType: BabApprovalType | undefined;
    occupations?: WorkingType[];
    membershipAppId: string;
};

export const WorkingLocations = ({ locations, babType, membershipAppId }: WorkingLocationsProps) => {
    const [form] = Form.useForm();

    const workLocationItems = locations.map((location, index) => {
        return (
            <>
                <Form
                    size="large"
                    colon={false}
                    labelWrap={true}
                    labelAlign="left"
                    form={form}
                    {...layout}
                    name="arbeitsorte"
                >
                    <WorkingLocation
                        membershipAppId={membershipAppId}
                        form={form}
                        babType={babType}
                        key={index}
                        location={location}
                        count={index}
                    />
                </Form>
            </>
        );
    });

    return (
        <>
            <div>{workLocationItems}</div>
        </>
    );
};
