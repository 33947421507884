import { Button, Layout, Typography } from 'antd';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import logo from '../agz_logo.jpg'; // Tell webpack this JS file uses this image
import { LogoutOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Header, Content } = Layout;

export const PageLayout = () => {
    const params = useParams();
    const navigate = useNavigate();
    const hasSecurityCode = !!params.securityCode;
    return (
        <Layout className="layout">
            <Header style={{ background: '#fff', height: '80px', paddingInline: '0' }}>
                <img style={{ marginLeft: '10px', marginTop: '5px', width: '400px' }} src={logo} alt="Logo" />
                {hasSecurityCode ? (
                    <div style={{ float: 'right', marginRight: '5px' }}>
                        <Button
                            size="large"
                            type="dashed"
                            danger
                            icon={<LogoutOutlined />}
                            onClick={() => navigate('/')}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Logout</span>
                        </Button>
                    </div>
                ) : null}
            </Header>
            <Content style={{ padding: '0 10px', background: '#fff' }}>
                <Title level={2}>Ihr Aufnahmeantrag</Title>
                <Outlet />
            </Content>
        </Layout>
    );
};
