import { Upload, Button, message, Form, UploadFile } from 'antd';
import { useDeleteDocumentMutation, useUploadImageMutation } from '../../../api/membership-api';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Document, FileType } from '../../../models';

type UploadDocumentProps = {
    label: string;
    name: string;
    membershipId: string;
    fileType: FileType;
    files: Document[] | undefined;
    onUploaded?: (uploaded: boolean) => void;
};

const allowedFileTypes = ['application/pdf'];
const allowedMaxSize = 10; //in mb

const setupFileList = (files: Document[], fileType: FileType): UploadFile[] => {
    if (!files) return [];

    let doc: Document | undefined;

    if (fileType === FileType.Bab1) {
        doc = files.find((x) => x.subject === 'BAB 01');
    }
    if (fileType === FileType.Bab2) {
        doc = files.find((x) => x.subject === 'BAB 02');
    }
    if (fileType === FileType.Tarmed) {
        doc = files.find((x) => x.subject === 'TARMED-Vertrag');
    }
    if (fileType === FileType.Okp1) {
        doc = files.find((x) => x.subject === 'OKP-Zulassung');
    }

    if (!doc) return [];

    return [
        {
            uid: doc.id || Date().toString(),
            name: doc.subject || '',
            status: 'done',
        },
    ];
};

export const UploadDocument = ({ label, name, membershipId, fileType, files, onUploaded }: UploadDocumentProps) => {
    const [uploadImage] = useUploadImageMutation();
    const [deleteDocument] = useDeleteDocumentMutation();
    const [fileList, setFileList] = useState<UploadFile[]>(setupFileList(files || [], fileType));

    const isValidFileType = (fileType: string) => {
        const found = allowedFileTypes.find((type) => type === fileType);

        return found;
    };

    const isValidSize = (fileSize: number) => {
        return fileSize / 1024 / 1024 < allowedMaxSize;
    };

    const beforeUpload = (file: any) => {
        const hasValidFileType = isValidFileType(file.type);
        if (!hasValidFileType) {
            void message.error('Nur PDF darf hochgeladen werden');
        }

        const hasValidSize = isValidSize(file.size);
        if (!hasValidSize) {
            void message.error('Datei muss kleiner als 10 MB sein');
        }

        return (hasValidFileType && hasValidSize) || Upload.LIST_IGNORE;
    };

    const handleChange = (data: any) => {
        setFileList(data.fileList);
    };

    const handleUploadRequest = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        fmData.append('image', file);
        uploadImage({
            formData: fmData,
            id: membershipId,
            type: fileType,
        });

        options.onSuccess('', options.file);

        if (onUploaded) onUploaded(true);
    };

    const onDelete = () => {
        const id = fileList[0]?.uid || '';
        if (id) {
            deleteDocument(id);
            if (onUploaded) onUploaded(false);
            return true;
        }

        return false;
    };

    const url = `/api/document/${fileList[0]?.uid}`;
    const labelWithSizeLimit = `${label} (max. 10mb)`;
    return (
        <>
            <Form.Item required name={name} label={labelWithSizeLimit}>
                <Upload
                    accept="application/pdf"
                    customRequest={(options) => handleUploadRequest(options)}
                    maxCount={1}
                    defaultFileList={fileList}
                    beforeUpload={beforeUpload}
                    name="logo"
                    listType="picture"
                    onChange={handleChange}
                    onRemove={() => onDelete()}
                >
                    {fileList && fileList.length > 0 ? null : (
                        <div>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </div>
                    )}
                </Upload>
                {fileList && fileList.length > 0 ? (
                    <a rel="noreferrer" target="_blank" href={url}>
                        Download
                    </a>
                ) : null}
            </Form.Item>
        </>
    );
};
