import { Alert, DatePicker, Form, InputNumber, Modal } from 'antd';
import { dateFormats } from '../../../../constants';
import { BabApprovalType, ClinicType, JobRole, WorkingType, WorkLocation } from '../../../../models';
import dayjs from 'dayjs';
import { CompanyInformation } from './CompanyInformation';
import { JobRoleField } from './JobRoleField';
import { TakeOverFields } from './TakeOverFields';
import { useUpdateWorkLocationMutation } from '../../../../api/membership-api';

type WorkingLocationEditFormProps = {
    open: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
    babType: BabApprovalType | undefined;
    location: WorkLocation;
    stayAtWorkLocation: boolean;
    occupations?: WorkingType[];
    membershipAppId: string;
};
export const WorkingLocationEditForm = ({
    open,
    onCreate,
    onCancel,
    babType,
    location,
    occupations,
    stayAtWorkLocation,
    membershipAppId,
}: WorkingLocationEditFormProps) => {
    const [form] = Form.useForm();
    const [updateWorkLocation, { isLoading }] = useUpdateWorkLocationMutation();

    const companyTypeWatch = Form.useWatch<ClinicType>('companyType', form);
    const jobRoleWatch = Form.useWatch<JobRole>('jobRole', form);

    const isSelfEmployed = jobRoleWatch === JobRole.SelbstaendigerArzt;

    const {
        companyType,
        companyName,
        companyNameAddition,
        street,
        zipcode,
        city,
        telephone,
        employmentPercentage,
        jobRole,
        from,
        to,
        takeOverClinicEmail,
        takeOverClinicFromName,
        takeOverClinicTelephone,
        workingLocationId,
        currentNumer,
        parentCustomerId,
    } = location;

    const required = occupations && !occupations?.includes(WorkingType.notActive);

    const showSelfEmployInfoFields =
        (companyTypeWatch === ClinicType.Einzelpraxis || companyTypeWatch === ClinicType.Gruppenpraxis) &&
        jobRoleWatch === JobRole.SelbstaendigerArzt;
    const onFinish = (values: any) => {
        if (stayAtWorkLocation) {
            updateWorkLocation({ id: membershipAppId, ...values, workingLocationId, currentNumer, to: null });
        } else {
            updateWorkLocation({
                id: membershipAppId,
                ...values,
                workingLocationId,
                currentNumer,
                employmentPercentage,
                jobRole,
                from,
                takeOverClinicFromName,
                takeOverClinicEmail,
                takeOverClinicTelephone,
            });
        }

        onCreate(values);
    };

    return (
        <>
            <Modal
                open={open}
                title={<h3>Arbeitsort bearbeiten</h3>}
                okText="Speichern"
                cancelText="Abbrechen"
                onCancel={onCancel}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            form.submit();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    size="middle"
                    layout="vertical"
                    onFinish={onFinish}
                    name="editForm"
                    initialValues={{
                        companyType,
                        companyName,
                        companyNameAddition,
                        street,
                        zipcode,
                        city,
                        telephone,
                        employmentPercentage,
                        jobRole,
                        from: from ? dayjs(from) : null,
                        to: to ? dayjs(to) : null,
                        takeOverClinicFromName,
                        takeOverClinicEmail,
                        takeOverClinicTelephone,
                    }}
                >
                    {stayAtWorkLocation ? (
                        <Alert
                            message="Bitte überprüfen Sie die Angaben zu Ihrer Rolle"
                            style={{ marginBottom: '20px' }}
                        />
                    ) : (
                        <Alert
                            message='Bitte tragen Sie bei "Hier tätig bis" Ihr Austrittsdatum ein.'
                            style={{ marginBottom: '20px' }}
                        />
                    )}

                    <CompanyInformation disabled={!!parentCustomerId} selectedCompanyType={companyTypeWatch} />
                    {stayAtWorkLocation ? (
                        <>
                            {companyTypeWatch ? (
                                <JobRoleField babType={babType} jobRole={jobRoleWatch} clinicType={companyTypeWatch} />
                            ) : null}

                            <Form.Item
                                rules={[{ required: isSelfEmployed }]}
                                name="employmentPercentage"
                                label="Stellenprozent"
                            >
                                <InputNumber min={1} max={100} />
                            </Form.Item>
                            <Form.Item rules={[{ required: isSelfEmployed }]} name="from" label="In dieser Rolle ab">
                                <DatePicker format={dateFormats} />
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item rules={[{ required }]} name="to" label="Hier tätig bis">
                            <DatePicker format={dateFormats} />
                        </Form.Item>
                    )}

                    {showSelfEmployInfoFields ? <TakeOverFields /> : null}
                </Form>
            </Modal>
        </>
    );
};
