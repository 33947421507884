import { Result } from 'antd';

export const SuccessPageAfterFinish = () => {
    return (
        <Result
            status="success"
            title="Gratulation! Ihr Antrag wurde erfolgreich abgeschlossen und wird nun vom Sekretariat der AGZ bearbeitet."
        />
    );
};
