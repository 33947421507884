import { Form, Input } from 'antd';

type PersonalContactDataProps = {
    privateMailHasValue: boolean;
    businessMailHasValue: boolean;
};

export const PersonalContactData = ({ privateMailHasValue, businessMailHasValue }: PersonalContactDataProps) => {
    return (
        <>
            <h3>Persönliche Kontaktdaten</h3>

            <Form.Item label="Berufliche Telefonnummer (z.B. Direktwahl am Hauptarbeitsort)" name="businessPhone">
                <Input />
            </Form.Item>
            <Form.Item label="Private Telefonnummer" name="privatePhone">
                <Input />
            </Form.Item>
            <Form.Item label="Mobilenummer" name="mobilePhone">
                <Input />
            </Form.Item>
            <Form.Item
                label="Private E-Mail-Adresse"
                rules={[{ required: !businessMailHasValue, type: 'email' }]}
                name="privateMail"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Persönliche geschäftliche E-Mail-Adresse (z.B. HIN-Adresse oder persönliche E-Mail-Adresse am Hauptarbeitsort)"
                name="businessMail"
                rules={[{ required: !privateMailHasValue, type: 'email' }]}
            >
                <Input />
            </Form.Item>
        </>
    );
};
