import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
    MembershipBaseData,
    EntityReference,
    FileType,
    MembershipMedicalEducationData,
    MembershipAdmissionData,
    MembershipWorkLocationData,
    MembershipContactData,
    MembershipMitgliedschaftenData,
    MembershipCompleteData,
    AccountDetail,
    WorkLocation,
} from '../models';

// Define a service using a base URL and expected endpoints
export const membershipApi = createApi({
    reducerPath: 'membershipApi',
    keepUnusedDataFor: 2,
    baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
    tagTypes: ['base', 'education', 'admission', 'workLocation', 'contact', 'mitgliedschaften', 'complete'],
    endpoints: (builder) => ({
        //Base Data step 1
        getCrmBaseData: builder.query<MembershipBaseData, string>({
            query: (securityCode) => `membershipdata/base/${securityCode}`,
            keepUnusedDataFor: 2,
            providesTags: ['base'],
        }),
        updateCrmMembershipBaseData: builder.mutation<
            MembershipBaseData,
            Partial<MembershipBaseData> & Pick<MembershipBaseData, 'id'>
        >({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/base/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['base'],
        }),

        //step 2
        getMembershipMedicalEducationData: builder.query<MembershipMedicalEducationData, string>({
            query: (securityCode) => `membershipdata/medicalEducation/${securityCode}`,
            providesTags: ['education'],
        }),
        updateCrmMembershipMedicalEducationData: builder.mutation<
            MembershipMedicalEducationData,
            Partial<MembershipMedicalEducationData> & Pick<MembershipMedicalEducationData, 'id'>
        >({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/medicalEducation/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['education'],
        }),

        //step 3
        getCrmMembershipAdmissionData: builder.query<MembershipAdmissionData, string>({
            query: (securityCode) => `membershipdata/admission/${securityCode}`,
            providesTags: ['admission'],
        }),
        updateCrmMembershipAdmissionData: builder.mutation<
            MembershipAdmissionData,
            Partial<MembershipAdmissionData> & Pick<MembershipAdmissionData, 'id'>
        >({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/admission/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['admission'],
        }),

        //step 4
        getCrmMembershipWorkLocationData: builder.query<MembershipWorkLocationData, string>({
            // query: (securityCode) => `membershipdata/worklocation/${securityCode}`,
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const memberShipDataResult = await fetchWithBQ(`membershipdata/worklocation/${_arg}`);
                if (memberShipDataResult.error) return { error: memberShipDataResult.error as FetchBaseQueryError };
                let memberShipData = memberShipDataResult.data as MembershipWorkLocationData;

                let { workLocation1, workLocation2, workLocation3, workLocation4 } = memberShipData;

                if (workLocation1?.parentCustomerId) {
                    const { data } = await fetchWithBQ(`accountDetail/${workLocation1.parentCustomerId?.id}`);
                    const { city, companyName, customerType, phoneNumber, street, zipCode } = data as AccountDetail;
                    workLocation1 = {
                        ...workLocation1,
                        city,
                        companyName,
                        companyType: customerType,
                        telephone: phoneNumber,
                        street,
                        zipcode: zipCode,
                    };
                    memberShipData = { ...memberShipData, workLocation1 };
                }

                if (workLocation2?.parentCustomerId) {
                    const { data } = await fetchWithBQ(`accountDetail/${workLocation2.parentCustomerId?.id}`);
                    const { city, companyName, customerType, phoneNumber, street, zipCode } = data as AccountDetail;
                    workLocation2 = {
                        ...workLocation2,
                        city,
                        companyName,
                        companyType: customerType,
                        telephone: phoneNumber,
                        street,
                        zipcode: zipCode,
                    };
                    memberShipData = { ...memberShipData, workLocation2 };
                }

                if (workLocation3?.parentCustomerId) {
                    const { data } = await fetchWithBQ(`accountDetail/${workLocation3.parentCustomerId?.id}`);
                    const { city, companyName, customerType, phoneNumber, street, zipCode } = data as AccountDetail;
                    workLocation3 = {
                        ...workLocation3,
                        city,
                        companyName,
                        companyType: customerType,
                        telephone: phoneNumber,
                        street,
                        zipcode: zipCode,
                    };
                    memberShipData = { ...memberShipData, workLocation3 };
                }

                if (workLocation4?.parentCustomerId) {
                    const { data } = await fetchWithBQ(`accountDetail/${workLocation4.parentCustomerId?.id}`);
                    const { city, companyName, customerType, phoneNumber, street, zipCode } = data as AccountDetail;
                    workLocation4 = {
                        ...workLocation4,
                        city,
                        companyName,
                        companyType: customerType,
                        telephone: phoneNumber,
                        street,
                        zipcode: zipCode,
                    };
                    memberShipData = { ...memberShipData, workLocation4 };
                }

                return memberShipData
                    ? { data: memberShipData as MembershipWorkLocationData }
                    : { error: memberShipDataResult.error as unknown as FetchBaseQueryError };
            },

            providesTags: ['workLocation'],
        }),

        createNewWorkLocation: builder.mutation<WorkLocation, Partial<WorkLocation> & Pick<WorkLocation, 'id'>>({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/worklocation/${id}`,
                method: 'POST',
                body: patch,
            }),
            invalidatesTags: ['workLocation'],
        }),
        updateWorkLocation: builder.mutation<WorkLocation, Partial<WorkLocation> & Pick<WorkLocation, 'id'>>({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/worklocation/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['workLocation'],
        }),

        getCrmMembershipContactData: builder.query<MembershipContactData, string>({
            query: (securityCode) => `membershipdata/contact/${securityCode}`,
            providesTags: ['contact'],
        }),
        updateCrmMembershipContactData: builder.mutation<
            MembershipContactData,
            Partial<MembershipContactData> & Pick<MembershipContactData, 'id'>
        >({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/contact/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['contact'],
        }),

        getCrmMembershipMitgliedschaftenData: builder.query<MembershipMitgliedschaftenData, string>({
            query: (securityCode) => `membershipdata/mitgliedschaften/${securityCode}`,
            providesTags: ['mitgliedschaften'],
        }),
        updateCrmMembershipMitgliedschaftenData: builder.mutation<
            MembershipMitgliedschaftenData,
            Partial<MembershipMitgliedschaftenData> & Pick<MembershipMitgliedschaftenData, 'id'>
        >({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/mitgliedschaften/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['mitgliedschaften'],
        }),

        getCrmMembershipCompleteData: builder.query<MembershipCompleteData, string>({
            query: (securityCode) => `membershipdata/complete/${securityCode}`,
            providesTags: ['complete'],
        }),
        updateCrmMembershipCompleteData: builder.mutation<
            MembershipCompleteData,
            Partial<MembershipCompleteData> & Pick<MembershipCompleteData, 'id'>
        >({
            query: ({ id, ...patch }) => ({
                url: `membershipdata/complete/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: ['complete'],
        }),
        getAccountDetail: builder.query<AccountDetail, string>({
            query: (accountId) => `accountDetail/${accountId}`,
        }),
        getProfessionSkills: builder.query<EntityReference[], string>({
            query: () => `professionSkills`,
        }),
        getProfessionFocusList: builder.query<EntityReference[], string>({
            query: () => `professionFocusList`,
        }),
        getCountries: builder.query<EntityReference[], string>({
            query: () => `countries`,
        }),
        uploadImage: builder.mutation<
            {},
            {
                id: string;
                type: FileType;
                formData: FormData;
            }
        >({
            query({ id, formData, type }) {
                return {
                    url: `document/${id}/${type}`,
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        downloadDocument: builder.query<{}, string>({
            query: (id) => `document/${id}`,
        }),
        deleteDocument: builder.mutation<{}, string>({
            query: (id) => ({
                url: `document/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetCrmBaseDataQuery,
    useUpdateCrmMembershipBaseDataMutation,
    useGetMembershipMedicalEducationDataQuery,
    useUpdateCrmMembershipMedicalEducationDataMutation,
    useGetCrmMembershipAdmissionDataQuery,
    useUpdateCrmMembershipAdmissionDataMutation,
    useGetCrmMembershipWorkLocationDataQuery,
    useUpdateWorkLocationMutation,
    useCreateNewWorkLocationMutation,
    useGetCrmMembershipContactDataQuery,
    useUpdateCrmMembershipContactDataMutation,
    useGetCrmMembershipMitgliedschaftenDataQuery,
    useUpdateCrmMembershipMitgliedschaftenDataMutation,
    useGetCrmMembershipCompleteDataQuery,
    useUpdateCrmMembershipCompleteDataMutation,
    useGetAccountDetailQuery,
    useGetProfessionSkillsQuery,
    useGetProfessionFocusListQuery,
    useGetCountriesQuery,
    useUploadImageMutation,
    useDeleteDocumentMutation,
} = membershipApi;
