import { Alert, Form, Select } from 'antd';
import { InvoiceAddressType } from '../../../models';

const { Option } = Select;

type PrivateAddressProps = {
    invoiceAddressType: InvoiceAddressType;
    privateAddressHasValues: boolean;
    arbeitsort1HasValues: boolean;
    arbeitsort2HasValues: boolean;
    arbeitsort3HasValues: boolean;
    arbeitsort4HasValues: boolean;
};

export const InvoiceAddress = ({
    invoiceAddressType,
    privateAddressHasValues,
    arbeitsort1HasValues,
    arbeitsort2HasValues,
    arbeitsort3HasValues,
    arbeitsort4HasValues,
}: PrivateAddressProps) => {
    const isPrivateAddressSelected = invoiceAddressType === InvoiceAddressType.privateAddress;

    return (
        <>
            <h3>Rechnungs- und Korrespondenzadresse</h3>
            <p>
                An diese Adresse erhalten Sie v.a. die Mitgliederbeitragsrechnungen und allfällige weitere offizielle
                Schreiben.
            </p>
            {isPrivateAddressSelected && !privateAddressHasValues ? (
                <Alert
                    banner
                    style={{ marginBottom: '15px' }}
                    message="Bitte geben Sie oben Ihre Privatadresse an"
                    type="info"
                    showIcon
                />
            ) : null}

            <Form.Item rules={[{ required: true }]} label="Gewünschte Zustelladresse" name="invoiceAddressType">
                <Select>
                    <Option value={InvoiceAddressType.privateAddress}>Privatadresse</Option>
                    <Option disabled={!arbeitsort1HasValues} value={InvoiceAddressType.companyAddress1}>
                        1. Arbeitsort
                    </Option>
                    <Option disabled={!arbeitsort2HasValues} value={InvoiceAddressType.companyAddress2}>
                        2. Arbeitsort
                    </Option>
                    <Option disabled={!arbeitsort3HasValues} value={InvoiceAddressType.companyAddress3}>
                        3. Arbeitsort
                    </Option>
                    <Option disabled={!arbeitsort4HasValues} value={InvoiceAddressType.companyAddress4}>
                        4. Arbeitsort
                    </Option>
                </Select>
            </Form.Item>
        </>
    );
};
