export interface AccountDetail {
    id?: string;
    companyName?: string;
    companyNameAddition?: string;
    street?: string;
    zipCode?: string;
    customerType?: ClinicType;
    phoneNumber?: string;
    city?: string;
}

export interface EntityReference {
    id?: string;
    logicalName?: string;
}

export interface MembershipBaseData {
    id?: string;
    academicTitle?: string;
    title?: string;
    genderCode?: GenderCode;
    firstname?: string;
    lastname?: string;
}

export interface MembershipMedicalEducationData {
    id?: string;
    facharztTitel1Code?: string;
    facharztTitel2Code?: string;
    facharztTitel3Code?: string;
    facharztTitel4Code?: string;
    issuedIn1?: string;
    issuedIn2?: string;
    issuedIn3?: string;
    issuedIn4?: string;
    approvedByMebekoOn1?: Date;
    approvedByMebekoOn2?: Date;
    approvedByMebekoOn3?: Date;
    approvedByMebekoOn4?: Date;
    inFurtherEducation?: boolean;
    professionSkills?: [];
    professionFocusList?: [];
}

export interface MembershipAdmissionData {
    id?: string;
    babType?: BabApprovalType;
    bab1ValidFrom?: Date;
    bab1ValidTo?: Date;
    bab2Type?: BabApprovalType;
    bab2ValidFrom?: Date;
    bab2ValidTo?: Date;
    additionalApproval?: AdditionalApproval;
    okpApprovalfa1Code?: OkpApprovalfaCode | null;
    okpApprovalfa2Code?: OkpApprovalfaCode | null;
    okpApprovalfa3Code?: OkpApprovalfaCode | null;
    okpApprovalfa4Code?: OkpApprovalfaCode | null;
    zsrOption?: ZsrRequiredOption;
    zsr1Id?: string;
    zsr2Id?: string;
    zsrRequiredReasonAsEmployeed?: string;
    zsrNotRequiredReason?: ZsrNotRequiredReason;
    files?: Document[];
    occupations?: WorkingType[];
    facharztTitel1Code?: string;
    facharztTitel2Code?: string;
    facharztTitel3Code?: string;
    facharztTitel4Code?: string;
}

export interface WorkLocation {
    id?: string;
    workingLocationId?: string;
    parentCustomerId?: EntityReference;
    jobRole?: JobRole;
    employmentPercentage?: Number;
    from?: Date;
    to?: Date;
    companyName?: string;
    companyNameAddition?: string;
    street?: string;
    zipcode?: string;
    city?: string;
    telephone?: string;
    companyType?: ClinicType;
    takeOverClinicFromName?: string;
    takeOverClinicTelephone?: string;
    takeOverClinicEmail?: string;
    order?: number;
    currentNumer?: number;
}

export interface MembershipWorkLocationData {
    id?: string;
    workLocation1?: WorkLocation;
    workLocation2?: WorkLocation;
    workLocation3?: WorkLocation;
    workLocation4?: WorkLocation;
    workLocations?: WorkLocation[];
    occupations?: WorkingType[];
    babType?: BabApprovalType;
    additionalApproval?: AdditionalApproval;
}

export interface MembershipContactData {
    id?: string;
    privateAddressAddition1?: string;
    privateAddressAddition2?: string;
    privateAddressStreet?: string;
    privateAddressZipCode?: string;
    privateAddressCity?: string;
    privateAddressCountryId?: string;
    businessPhone?: string;
    privatePhone?: string;
    mobilePhone?: string;
    privateMail?: string;
    businessMail?: string;
    invoiceAddressType?: InvoiceAddressType;
    zazDeliveryAddressType?: ZazDeliveryAddressType;
    agzNewsletterType?: AgzNewsletterType;
    hasArbeitsort1Values?: boolean;
    hasArbeitsort2Values?: boolean;
    hasArbeitsort3Values?: boolean;
    hasArbeitsort4Values?: boolean;
}

export interface MembershipMitgliedschaftenData {
    id?: string;
    noMemberBg?: boolean;
    bgEmailApproval?: boolean;
    vsaoMember?: boolean;
    vsaoMembershipNr?: string;
    combiMemberVsao?: boolean;
    vlssMember?: boolean;
    vlssMembershipNr?: string;
    fmhMember?: boolean;
    fmhMembershipNr?: string;
    isHospitalLeadDoctor?: boolean;
    isLeadDoctor?: boolean;
    isHospitalDoctor?: boolean;
}
export interface MembershipCompleteData {
    id?: string;
    commitment?: boolean;
    mitteilung?: string;
}

export enum InvoiceAddressType {
    companyAddress1 = 100,
    companyAddress2 = 101,
    companyAddress3 = 102,
    companyAddress4 = 103,
    privateAddress = 105,
}

export enum AgzNewsletterType {
    nein = 1,
    businessEmail = 2,
    privateEmail = 3,
}
export enum ZazDeliveryAddressType {
    privateAddress = 3,
    invoiceAddress = 2,
}

export interface Document {
    id?: string;
    subject?: string;
    fileName?: string;
}

export enum GenderCode {
    male = 1,
    female = 2,
}

export enum BabApprovalType {
    babFor10Years = 100000000,
    babFromAge70Years = 100000002,
    babFor90Days = 100000001,
    noBab = 4,
}

export enum WorkingType {
    selfEmployed = 1,
    employed = 2,
    notActive = 3,
}

export enum AdditionalApproval {
    assistanceApproval = 1,
    employedInHospital = 2,
    noApproval = 3,
}

export enum OkpApprovalfaCode {
    selfEmployed = 915240000,
    employed = 915240001,
    selfEmplyedAndEmployed = 915240002,
    noApproval = 915240003,
}

export enum ZsrRequiredOption {
    required = 1,
    alreadyExist = 2,
    noZsrRequired = 3,
}

export enum ZsrNotRequiredReason {
    onlyPrivateInvoicingPlanned = 1,
    movedToForeignCountry = 2,
    GDZurichNotDecidedYet = 3,
}

export enum FileType {
    Bab1 = 0,
    Bab2 = 1,
    Tarmed = 2,
    Okp1 = 3,
    Okp2 = 4,
    Okp3 = 5,
    Okp4 = 6,
}

export enum JobRole {
    SelbstaendigerArzt = 100000000,
    AngestellterArzt = 100000004,
    Institutsleiter = 100000001,
    AssistenzarztInPraxis = 915240001,
    LeitenderSpitalarzt = 100000002,
    Spitalfacharzt = 100000006,
    Sonstige = 100000003,
}

export enum ClinicType {
    Einzelpraxis = 1,
    Gruppenpraxis = 2,
    Institut = 3,
    Poliklinik = 13,
    Spital = 4,
    Andere = 11,
}

export enum AusgestelltIn {
    AusgestelltInSwiss = 1,
    AusgestelltInAusland = 2,
}

export const JobRoleEnumLabel: ModelEnum[] = [
    { key: JobRole.SelbstaendigerArzt, displayValue: 'Selbständiger Arzt' },
    { key: JobRole.AngestellterArzt, displayValue: 'Angestellter Arzt' },
    { key: JobRole.Institutsleiter, displayValue: 'Institutsleiter' },
    { key: JobRole.AssistenzarztInPraxis, displayValue: 'Assistenzarzt in Praxis' },
    { key: JobRole.LeitenderSpitalarzt, displayValue: 'Leitender Spitalarzt' },
    { key: JobRole.Spitalfacharzt, displayValue: 'Spitalfacharzt' },
    { key: JobRole.Sonstige, displayValue: 'Sonstige' },
];
export interface ModelEnum {
    key: number;
    displayValue: string;
}
