import { Form, Select } from 'antd';
import { BabApprovalType, ClinicType, JobRole, JobRoleEnumLabel } from '../../../../models';
import { BabInfo } from '../BabInfo';
import { CompanyRoleMapping } from '../CompanyRoleMapping';

const { Option } = Select;

type JobRoleFieldProps = { babType: BabApprovalType | undefined; jobRole: JobRole; clinicType: ClinicType };

export const JobRoleField = ({ babType, jobRole, clinicType }: JobRoleFieldProps) => {
    const showBabInfo = babType !== BabApprovalType.noBab && jobRole === JobRole.AssistenzarztInPraxis;
    const companyJobRoles = CompanyRoleMapping.find((c) => c.type.includes(clinicType))?.role;
    return (
        <>
            {showBabInfo ? <BabInfo /> : null}
            <Form.Item name="jobRole" label="Rolle">
                <Select>
                    {companyJobRoles?.map((role) => {
                        if (!clinicType) return null;
                        return (
                            <Option value={role} key={role}>
                                {JobRoleEnumLabel.filter((x) => x.key === role)[0]?.displayValue}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </>
    );
};
