import { Alert, Button, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

export const layout = {
    labelCol: {
        xxl: { span: 24, offset: 0 },
    },
    wrapperCol: {
        lg: { span: 10, offset: 1, pull: 1 },
        xl: { span: 6, offset: 1, pull: 1 },
        xxl: { span: 6, offset: 1, pull: 1 },
    },
};

export const SecurityCodeLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish = ({ securityCode }: any) => {
        navigate(`/check/${securityCode}`);
    };

    return (
        <>
            {location.state?.error ? <Alert showIcon type="error" message="Ungültiger Code" /> : null}
            <h1>Login</h1>
            <Form
                colon={false}
                size="large"
                labelAlign="left"
                {...layout}
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
            >
                <Form.Item name="securityCode" label="Sicherheitscode">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
