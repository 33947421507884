import { Alert, Form, Select } from 'antd';
import { AgzNewsletterType } from '../../../models';

const { Option } = Select;

type DeliveryAgzNewsletterProps = {
    privateMailHasValue: boolean;
    businessMailHasValue: boolean;
    newsletterType: AgzNewsletterType;
};

export const DeliveryAgzNewsletter = ({
    businessMailHasValue,
    privateMailHasValue,
    newsletterType,
}: DeliveryAgzNewsletterProps) => {
    return (
        <>
            <h3>Zustellung AGZ-Newsletter</h3>
            <p>
                Aus dem AGZ Newsletter erfahren Sie Aktuelles und Wichtiges, das Sie als Ärztin oder Arzt wissen müssen.
                Unseren elektronischen Newsletter verschicken wir nur bei Bedarf,
                <br /> etwa ein bis zweimal pro Monat. Per Mailing laden wir zudem zu AGZ-Veranstaltungen ein. <br />
                Mit Ihrer hier registrierten Mailadresse können Sie sich zu diesen Mitgliederveranstaltungen online
                anmelden
            </p>
            {newsletterType === AgzNewsletterType.privateEmail && !privateMailHasValue ? (
                <Alert
                    banner
                    style={{ marginBottom: '15px' }}
                    message="Bitte geben Sie oben Ihre private E-Mail-Adresse an"
                    type="info"
                    showIcon
                />
            ) : null}

            {newsletterType === AgzNewsletterType.businessEmail && !businessMailHasValue ? (
                <Alert
                    banner
                    style={{ marginBottom: '15px' }}
                    message="Bitte geben Sie oben Ihre geschäftliche E-Mail-Adresse an"
                    type="info"
                    showIcon
                />
            ) : null}

            <Form.Item rules={[{ required: true }]} label="Gewünschte Zustelladresse" name="agzNewsletterType">
                <Select>
                    <Option value={AgzNewsletterType.privateEmail}>Private E-Mail-Adresse</Option>
                    <Option value={AgzNewsletterType.businessEmail}>Persönliche geschäftliche E-Mail-Adresse</Option>
                </Select>
            </Form.Item>
        </>
    );
};
