import { Row, Col, Button, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { GenderCode } from '../../models';
import { useGetCrmBaseDataQuery } from '../../api/membership-api';
import { BaseStepProps } from './StepBase';

export const WelcomeStep = ({ setStep }: BaseStepProps) => {
    let params = useParams();
    const navigate = useNavigate();
    const {
        data: memberShipData,
        error: membershipDataLoadingError,
        isLoading: isLoadingMembershipData,
    } = useGetCrmBaseDataQuery(params.securityCode!);

    const { lastname, genderCode, title } = memberShipData || {};

    const titleText = title || '';

    if (isLoadingMembershipData) return <Skeleton active />;
    let salutation = genderCode === GenderCode.male ? 'Sehr geehrter Herr' : 'Sehr geehrte Frau';
    if (!genderCode) salutation = 'Guten Tag';

    if (isLoadingMembershipData) return <Skeleton active />;

    if (membershipDataLoadingError) {
        navigate(`/`);
    }
    return (
        <>
            <h2>Willkommen</h2>
            <Row>
                <Col>
                    <p>
                        {salutation} {titleText} {lastname}
                    </p>
                    <p>Wir freuen uns, dass Sie der Zürcher Ärztegesellschaft beitreten möchten.</p>

                    <p>
                        Für Ihre Aufnahme benötigen wir noch einige weitere Angaben. Bitte vervollständigen Sie auf den
                        folgenden Seiten Ihren Aufnahmeantrag. Sofern wir von Ihnen bereits amtliche Daten kennen und
                        erfasst haben, werden Ihnen diese zur Kontrolle oder Korrektur vorgeschlagen. Wir hoffen, Ihnen
                        die Antragsstellung damit zu erleichtern.{' '}
                    </p>
                    <p>
                        Sie können sich mehrmals einloggen und Ihre Daten Schritt für Schritt hinterlegen und auch
                        frühere Einträge korrigieren. Ihr Logincode bleibt aktiv, solange Sie Ihren Antrag nicht
                        abschliessend einreichen.
                    </p>

                    <p>
                        Ihren vollständigen Antrag inkl. der nötigen Dokumente als Upload reichen Sie abschliessend
                        bitte via Button «Antrag an AGZ senden» ein.
                    </p>
                    <p>
                        Die Mitarbeitenden des AGZ-Mitgliederwesens werden Ihnen den Eingang Ihres Antrags per E-Mail
                        bestätigen, Sie melden sich zudem bei Ihnen, falls in der Prüfung und Vorbereitung des
                        Aufnahmeentscheids durch den AGZ-Vorstand noch offene Fragen auftauchen.
                    </p>
                    <p>
                        Über Ihre Aufnahme wird dann der Vorstand der AGZ in seiner nächsten Sitzung entscheiden. Der
                        AGZ-Vorstand tagt etwa einmal pro Monat.
                    </p>
                    <p>
                        Sollten Sie beim Ausfüllen Ihres Antrags Hilfe benötigen, stehen Ihnen die AGZ-Mitarbeitenden
                        gerne zur Seite, per E-Mail unter{' '}
                        <a href="mailto: mitgliederwesen.agz@hin.ch">mitgliederwesen.agz@hin.ch</a> oder per Telefon +41
                        44 421 14 14.
                    </p>
                    <p>Wir freuen uns auf Ihren Aufnahmeantrag.</p>
                    <p>Herzliche Grüsse, Ihre</p>
                    <p>AGZ AERZTEGESELLSCHAFT DES KANTONS ZUERICH</p>
                </Col>
            </Row>
            <Row>
                <Col xl={1} lg={24} sm={24} xs={24}>
                    <Button type="primary" onClick={() => setStep(1)} size="large">
                        Weiter
                    </Button>
                </Col>
            </Row>
        </>
    );
};
