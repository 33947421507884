import { Steps } from 'antd';
import { useState } from 'react';
import { AbschlussStep } from './stepper/AbschlussStep/AbschlussStep';
import { ArbeitsorteStep } from './stepper/ArbeitsorteStep/ArbeitsorteStep';
import { ZulassungsStep } from './stepper/ZulassungsStep/ZulassungsStep';
import { WelcomeStep } from './stepper/WelcomeStep';
import { MitgliedschaftenStep } from './stepper/MitgliedschaftenStep/MitgliedschaftenStep';
import { KontaktDatenStep } from './stepper/KontaktDatenStep/KontaktDatenStep';
import { AusWeiterbildungStep } from './stepper/AusWeiterbildungStep/AusWeiterbildungStep';
import 'dayjs/locale/de-ch';
import dayjs from 'dayjs';

dayjs.locale('de-ch');

const getSteps = () => {
    return [
        {
            stepIndex: 0,
            title: 'Willkommen',
            contentpage: WelcomeStep,
        },
        { stepIndex: 1, title: 'Aus- und Weiterbildung', contentpage: AusWeiterbildungStep },
        { stepIndex: 2, title: 'Zulassung', contentpage: ZulassungsStep },
        { stepIndex: 3, title: 'Arbeitsorte', contentpage: ArbeitsorteStep },
        { stepIndex: 4, title: 'Kontaktdaten', contentpage: KontaktDatenStep },
        { stepIndex: 5, title: 'Mitgliedschaften', contentpage: MitgliedschaftenStep },
        { stepIndex: 6, title: 'Abschluss', contentpage: AbschlussStep },
    ];
};

export const MembershipApp = () => {
    const [current, setCurrent] = useState(0);

    const getCurrentStep = () => {
        return getSteps().filter((step) => step.stepIndex === current)[0];
    };

    const currentStep = getCurrentStep();

    const ContentComponent = currentStep.contentpage;
    return (
        <>
            <Steps style={{ paddingTop: '40px' }} current={current} items={getSteps()} />
            <ContentComponent setStep={setCurrent} />
            <div style={{ marginTop: '20px' }} className="steps-content"></div>
        </>
    );
};
