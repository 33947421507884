import { Button, Col, Form, message, Radio, Row, Skeleton, Input, Typography} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useGetCrmMembershipCompleteDataQuery,
    useUpdateCrmMembershipCompleteDataMutation,
} from '../../../api/membership-api';
import { BaseStepProps, validateMessages } from '../StepBase';

const { TextArea } = Input;
const { Text, Link } = Typography;
export const layout = {
    labelCol: {
        xxl: { span: 24, offset: 0 },
    },
    wrapperCol: {
        xxl: { span: 6, offset: 1, pull: 1 },
    },
};

export const AbschlussStep = ({ setStep }: BaseStepProps) => {
    const [form] = Form.useForm();
    let params = useParams();
    const navigate = useNavigate();

    const commitmentWatch = Form.useWatch('commitment', form);

    const [updateMembershipData, { isLoading: isUpdating }] = useUpdateCrmMembershipCompleteDataMutation();

    const {
        data: memberShipData,
        error: membershipDataLoadingError,
        isLoading: isLoadingMembershipData,
    } = useGetCrmMembershipCompleteDataQuery(params.securityCode!);

    const { id, commitment, mitteilung } = memberShipData || {};

    const onFinish = async (values: any) => {
        await updateMembershipData({ ...values, id });
        message.success('Daten aktualisiert', 2.5);

        navigate(`/app/complete`);
    };

    if (isLoadingMembershipData) return <Skeleton active />;

    return (
        <>
            <Form
                colon={false}
                labelWrap={true}
                labelAlign="left"
                form={form}
                disabled={isUpdating}
                {...layout}
                layout="vertical"
                name="finish"
                onFinish={(v) => onFinish(v)}
                validateMessages={validateMessages}
                initialValues={{ commitment, mitteilung }}
            >
                <h2>Aufnahmeantrag abschliessen</h2>

                <Form.Item
                    name="commitment"
                    label={
                        
                    <p>Mit meiner Unterschrift verpflichte ich mich, die Statuten, die Standesordnung und die Beschlüsse der zuständigen Organe der AGZ
                    AERZTEGESELLSCHAFT DES KANTONS ZUERICH sowie die Statuten der Verbindung der Schweizer Ärzte
                    und Ärztinnen (FMH), die Beschlüsse der Schweizerischen Ärztekammer und die durch
                    Urabstimmung gefassten Beschlüsse anzuerkennen.<br/>
                    Die Statuten und die Standesordnung der AGZ finden Sie <a href="https://aerzte-zh.ch/gesellschaft/" target="_blank">hier</a><br/>
                    Die Statuten der FMH finden Sie <a href="https://www.fmh.ch/ueber-die-fmh/statuten-reglemente.cfm" target="_blank">hier</a> </p>
                    
                    
                    }
                >
                    <Radio.Group>
                        <Radio value={true}>Ja</Radio>
                        <Radio value={false}>Nein</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Möchten Sie uns noch etwas mitteilen?" name="mitteilung">
                    <TextArea />
                </Form.Item>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                    <Col>
                        <Form.Item>
                            <Button onClick={() => setStep(5)}>Zurück</Button>
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item>
                            <Button disabled={!commitmentWatch} type="primary" htmlType="submit">
                                Antrag an AGZ senden
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
