import { Form, Select, message, Radio, Skeleton } from 'antd';
import { BaseStepProps, layout, validateMessages } from '../StepBase';
import {
    useGetMembershipMedicalEducationDataQuery,
    useGetProfessionFocusListQuery,
    useGetProfessionSkillsQuery,
    useUpdateCrmMembershipMedicalEducationDataMutation,
} from '../../../api/membership-api';
import { FacharztTitel } from './Facharzttitel';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { NavigationButton } from '../NavigationButton';

const { Option } = Select;

const codeOhneFacharzttitel = 915240029;

export const AusWeiterbildungStep = ({ setStep }: BaseStepProps) => {
    const [form] = Form.useForm();
    let params = useParams();

    const facharztTitel1Watch = Form.useWatch('facharztTitel1Code', form);
    const facharztTitel2Watch = Form.useWatch('facharztTitel2Code', form);
    const facharztTitel3Watch = Form.useWatch('facharztTitel3Code', form);
    const facharztTitel4Watch = Form.useWatch('facharztTitel4Code', form);
    const issuedInWatch1 = Form.useWatch('issuedIn1', form);
    const issuedInWatch2 = Form.useWatch('issuedIn2', form);
    const issuedInWatch3 = Form.useWatch('issuedIn3', form);
    const issuedInWatch4 = Form.useWatch('issuedIn4', form);

    const [updateMembershipData, { isLoading: isUpdating, isSuccess: isSuccessUpdated }] =
        useUpdateCrmMembershipMedicalEducationDataMutation();

    const {
        data: memberShipData,
        error: membershipDataLoadingError,
        isLoading: isLoadingMembershipData,
        isFetching,
    } = useGetMembershipMedicalEducationDataQuery(params.securityCode!);

    const {
        id,
        facharztTitel1Code,
        facharztTitel2Code,
        facharztTitel3Code,
        facharztTitel4Code,
        approvedByMebekoOn1,
        approvedByMebekoOn2,
        approvedByMebekoOn3,
        approvedByMebekoOn4,
        issuedIn1,
        issuedIn2,
        issuedIn3,
        issuedIn4,
        inFurtherEducation,
        professionSkills,
        professionFocusList,
    } = memberShipData || {};

    const {
        data: allProfessionSkills,
        error: professionSkillLoadingError,
        isFetching: isLoadingProfessionSkills,
    } = useGetProfessionSkillsQuery('');
    const {
        data: allProfessionFocusList,
        error: professionFocusListLoadingError,
        isFetching: isLoadingProfessionFocusList,
    } = useGetProfessionFocusListQuery('');

    const onFinish = async (values: any) => {
        await updateMembershipData({ ...values, id });
        message.success('Daten aktualisiert', 3);
    };

    const isBusy = isLoadingMembershipData || isLoadingProfessionFocusList || isLoadingProfessionSkills || isFetching;

    if (isBusy) return <Skeleton active />;

    const hasFachArztTitel = facharztTitel1Watch !== codeOhneFacharzttitel;

    const displaySwitches =
        facharztTitel1Code !== facharztTitel1Watch ||
        facharztTitel2Code !== facharztTitel2Watch ||
        facharztTitel3Code !== facharztTitel3Watch ||
        facharztTitel4Code !== facharztTitel4Watch;

    return (
        <>
            <h2>Aus- und Weiterbildung</h2>

            <Form
                size="large"
                colon={false}
                labelWrap={true}
                validateMessages={validateMessages}
                labelAlign="left"
                disabled={isUpdating}
                form={form}
                {...layout}
                name="basic"
                onFinish={(values) => onFinish(values)}
                initialValues={{
                    inFurtherEducation,
                    issuedIn1,
                    issuedIn2,
                    issuedIn3,
                    issuedIn4,
                    facharztTitel1Code,
                    facharztTitel2Code,
                    facharztTitel3Code,
                    facharztTitel4Code,
                    approvedByMebekoOn1: approvedByMebekoOn1 ? dayjs(approvedByMebekoOn1) : null,
                    approvedByMebekoOn2: approvedByMebekoOn2 ? dayjs(approvedByMebekoOn2) : null,
                    approvedByMebekoOn3: approvedByMebekoOn3 ? dayjs(approvedByMebekoOn3) : null,
                    approvedByMebekoOn4: approvedByMebekoOn4 ? dayjs(approvedByMebekoOn4) : null,
                    professionSkills,
                    professionFocusList,
                }}
            >
                <FacharztTitel
                    facharztTitelCode={facharztTitel1Code}
                    facharztTitelCodeWatcher={facharztTitel1Watch}
                    issuedIn={issuedInWatch1}
                    required={true}
                    count={1}
                    form={form}
                />

                {!hasFachArztTitel ? (
                    <Form.Item
                        name="inFurtherEducation"
                        label="Befinden Sie sich in Weiterbildung (in SIWF-Curriculum zu einem Facharzttitel oder Schwerpunkt)?"
                    >
                        <Radio.Group>
                            <Radio value={true}>Ja</Radio>
                            <Radio value={false}>Nein</Radio>
                        </Radio.Group>
                    </Form.Item>
                ) : null}

                {!!facharztTitel1Watch && hasFachArztTitel ? (
                    <FacharztTitel
                        facharztTitelCode={facharztTitel2Code}
                        facharztTitelCodeWatcher={facharztTitel2Watch}
                        issuedIn={issuedInWatch2}
                        initialToggle={displaySwitches && !!facharztTitel2Code}
                        showByDefault={false}
                        count={2}
                        form={form}
                    />
                ) : null}
                {!!facharztTitel2Watch ? (
                    <FacharztTitel
                        facharztTitelCode={facharztTitel3Code}
                        facharztTitelCodeWatcher={facharztTitel3Watch}
                        issuedIn={issuedInWatch3}
                        initialToggle={!!facharztTitel3Code}
                        showByDefault={false}
                        count={3}
                        form={form}
                    />
                ) : null}

                {!!facharztTitel3Watch ? (
                    <FacharztTitel
                        facharztTitelCode={facharztTitel4Code}
                        issuedIn={issuedInWatch4}
                        facharztTitelCodeWatcher={facharztTitel4Watch}
                        initialToggle={!!facharztTitel4Code}
                        showByDefault={false}
                        count={4}
                        form={form}
                    />
                ) : null}
                {hasFachArztTitel ? (
                    <>
                        <Form.Item
                            name="professionFocusList"
                            label="Schwerpunkte"
                            tooltip="Informationen für das Ausstellen und Anerkennen von Schwerpunkten finden Sie beim Schweizerischen Institut für Weiter- und Fortbildung SIWF der FMH:
                    SIWF
                    Elfenstrasse 18
                    Postfach
                    CH-3000 Bern 16
                    Tel. 031 503 06 00
                    E-Mail: info@siwf.ch"
                        >
                            <Select
                                mode="multiple"
                                placeholder="Schwerpunkte auswählen"
                                loading={isLoadingProfessionFocusList}
                            >
                                {allProfessionFocusList?.map((focus) => (
                                    <Option key={focus.id} value={focus.id}>
                                        {focus.logicalName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="professionSkills"
                            label="Fähigkeitsausweise"
                            tooltip="Informationen für das Ausstellen und Anerkennen von Fähigkeitsausweisen finden Sie beim Schweizerischen Institut für Weiter- und Fortbildung SIWF der FMH:
                    SIWF
                    Elfenstrasse 18
                    Postfach
                    CH-3000 Bern 16
                    Tel. 031 503 06 00
                    E-Mail: info@siwf.ch"
                        >
                            <Select
                                loading={isLoadingProfessionSkills}
                                mode="multiple"
                                placeholder="Fähigkeitsausweise auswählen"
                            >
                                {allProfessionSkills?.map((skill) => (
                                    <Option key={skill.id} value={skill.id}>
                                        {skill.logicalName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                ) : null}
                <NavigationButton
                    onNext={async () => {
                        await form.validateFields();
                        const values = form.getFieldsValue();
                        await onFinish(values);
                        setStep(2);
                    }}
                    onBack={() => setStep(0)}
                    showSaveButton={true}
                />
            </Form>
        </>
    );
};
