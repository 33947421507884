import { Alert, Col, Row } from 'antd';

export const BabInfo = () => {
    return (
        <Row>
            <Col xl={24}>
                <Alert
                    banner
                    style={{ marginBottom: '15px' }}
                    message="  Sie haben laut obigen Angaben eine Berufsausübungsbewilligung (BAB) zur eigenverantwortlichen
                ärztlichen Tätigkeit im Kanton Zürich. 
                Damit können Sie nicht als Assistenzarzt in der Praxis arbeiten. Wenn Sie nicht selbständig tätig
                werden, wählen Sie bitte angestellter Arzt"
                    type="info"
                    showIcon
                />
            </Col>
        </Row>
    );
};
