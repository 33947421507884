import { Col, Form, message, Skeleton, Select, DatePicker, Switch, Alert } from 'antd';
import { BaseStepProps, validateMessages } from '../StepBase';
import { useParams } from 'react-router-dom';
import { dateFormats } from '../../../constants';
import { UploadDocument } from './UploadDocument';
import { WorkingState } from './WorkingState';
import {
    AdditionalApproval,
    BabApprovalType,
    FileType,
    OkpApprovalfaCode,
    WorkingType,
    ZsrRequiredOption,
} from '../../../models';
import { OkpApproval } from './OkpApproval';
import { ZsrNumber } from './ZsrNumber';
import { TarmedContract } from './TarmedContract';
import dayjs from 'dayjs';
import {
    useGetCrmMembershipAdmissionDataQuery,
    useUpdateCrmMembershipAdmissionDataMutation,
} from '../../../api/membership-api';
import { NavigationButton } from '../NavigationButton';
import { BabType } from './BabType';

const { Option } = Select;

export const layout = {
    labelCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        lg: { span: 12 },
        xl: { span: 7 },
        xxl: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24, offset: 1, pull: 1 },
        sm: { span: 24, offset: 1, pull: 1 },
        lg: { span: 12 },
        xl: { span: 12, offset: 0 },
        xxl: { span: 10, offset: 1 },
    },
};

//Step: Zulassung zur ärztlichen Tätigkeit im Kanton Zürich
export const ZulassungsStep = ({ setStep }: BaseStepProps) => {
    const [form] = Form.useForm();
    let params = useParams();

    const babTypeWatch = Form.useWatch('babType', form);
    const bab2TypeWatch = Form.useWatch('bab2Type', form);
    const additionalBabWatch = Form.useWatch('hasAdditionalBab', form);
    const additionalApprovalWatch = Form.useWatch<AdditionalApproval>('additionalApproval', form);
    const workingTypesWatch = Form.useWatch<WorkingType[]>('workingtypes', form);
    const bab1ValidFromWatch = Form.useWatch<Date>('bab1ValidFrom', form);
    const bab2ValidFromWatch = Form.useWatch<Date>('bab2ValidFrom', form);
    const bab1ValidToWatch = Form.useWatch<Date>('bab1ValidTo', form);
    const okpApproval1Watch = Form.useWatch<OkpApprovalfaCode>('okpApprovalfa1Code', form);
    const okpApproval2Watch = Form.useWatch<OkpApprovalfaCode>('okpApprovalfa2Code', form);
    const okpApproval3Watch = Form.useWatch<OkpApprovalfaCode>('okpApprovalfa3Code', form);
    const okpApproval4Watch = Form.useWatch<OkpApprovalfaCode>('okpApprovalfa4Code', form);
    const zsrOptionWatch = Form.useWatch<ZsrRequiredOption>('zsrOption', form);

    const {
        data: memberShipData,
        error: isMembershipDataLoadingError,
        isLoading: isLoadingMembershipData,
        isFetching,
        isSuccess,
    } = useGetCrmMembershipAdmissionDataQuery(params.securityCode!);

    const {
        id,
        files,
        occupations,
        bab1ValidFrom,
        bab1ValidTo,
        bab2ValidTo,
        bab2Type,
        bab2ValidFrom,
        additionalApproval,
        okpApprovalfa1Code,
        okpApprovalfa2Code,
        okpApprovalfa3Code,
        okpApprovalfa4Code,
        facharztTitel1Code,
        facharztTitel2Code,
        facharztTitel3Code,
        facharztTitel4Code,
        zsrOption,
        zsr1Id,
        zsr2Id,
        zsrRequiredReasonAsEmployeed,
        zsrNotRequiredReason,
        babType,
    } = memberShipData || {};

    const [updateMembershipData, { isLoading: isUpdating }] = useUpdateCrmMembershipAdmissionDataMutation();

    const onFinish = async (values: any) => {
        const requestParams = {
            ...values,
            occupations: workingTypesWatch,
        };

        await updateMembershipData({ ...requestParams, id });
        message.success('Daten aktualisiert', 3);
    };

    const isBusy = isFetching || isLoadingMembershipData;

    const hasValidOccupation =
        workingTypesWatch?.includes(WorkingType.employed) || workingTypesWatch?.includes(WorkingType.selfEmployed);
    const hasNoBab = babTypeWatch === BabApprovalType.noBab;
    const hasNoBab2 = bab2TypeWatch === BabApprovalType.noBab;

    const isSelfEmployeed = workingTypesWatch?.includes(WorkingType.selfEmployed);
    const isEmplyoeed = workingTypesWatch?.includes(WorkingType.employed);
    const isOnlyEmplyoeed = isEmplyoeed && workingTypesWatch.length === 1;

    const calculateValidToDate = (
        bab: 'bab1' | 'bab2',
        babValidFrom?: dayjs.Dayjs | null,
        babType?: BabApprovalType | null,
    ) => {
        if (bab === 'bab1') {
            const babApprovalType = babType || babTypeWatch;
            const validFrom = babValidFrom || bab1ValidFromWatch;

            if (babApprovalType !== BabApprovalType.babFor90Days && babApprovalType !== BabApprovalType.babFor10Years)
                return bab1ValidTo;

            if (!validFrom || bab1ValidTo) return null;

            if (babApprovalType === BabApprovalType.babFor10Years) {
                return dayjs(validFrom).add(10, 'years');
            }

            if (babApprovalType === BabApprovalType.babFor90Days) {
                return dayjs(validFrom).endOf('year').add(-10, 'hours');
            }
            return bab1ValidTo;
        } else if (bab === 'bab2') {
            const babApprovalType = babType || bab2TypeWatch;
            const validFrom = babValidFrom || bab2ValidFromWatch;

            if (babApprovalType !== BabApprovalType.babFor90Days && babApprovalType !== BabApprovalType.babFor10Years)
                return bab2ValidTo;

            if (!validFrom || bab2ValidTo) return null;

            if (babApprovalType === BabApprovalType.babFor10Years) {
                return dayjs(validFrom).add(10, 'years');
            }

            if (babApprovalType === BabApprovalType.babFor90Days) {
                return dayjs(validFrom).endOf('year');
            }

            return null;
        }
    };

    const validToValue1 = calculateValidToDate('bab1');
    const validToValue2 = calculateValidToDate('bab2');

    // Die Meldung bei Zulassungsstep nur anzeigen wenn “als selbständiger arzt oder als angestellter arzt tätig” plus keine BAB. Falls nur angestellter arzt und “andere bewiliigigung: keine Bewilligung dann anzeigen
    const showInfo =
        (isEmplyoeed && workingTypesWatch.length === 1 && additionalApprovalWatch === AdditionalApproval.noApproval) ||
        (hasNoBab && isSelfEmployeed);

    const noUploadRequired =
        babType === babTypeWatch &&
        dayjs(bab1ValidFrom) &&
        dayjs(bab1ValidFrom).isSame(bab1ValidFromWatch) &&
        dayjs(bab1ValidTo).isSame(bab1ValidToWatch);

    const hasAdditionalBab = !!bab2ValidFrom;

    //wenn richtiger bewilligungstyp im crm gesetzt ist und BAB Gültig bis in zukunft liegt dann die felder sperren.
    const babReadOnly =
        babType === babTypeWatch &&
        !hasNoBab &&
        dayjs(bab1ValidTo).isSame(bab1ValidToWatch, 'day') &&
        dayjs(bab1ValidTo).isAfter(dayjs());

    if (isMembershipDataLoadingError) {
        return <div>Error loading while loading data...</div>;
    }

    if (isBusy) {
        return <Skeleton active />;
    }

    const mustUpload =
        okpApproval1Watch === OkpApprovalfaCode.selfEmplyedAndEmployed ||
        okpApproval2Watch === OkpApprovalfaCode.selfEmplyedAndEmployed ||
        okpApproval3Watch === OkpApprovalfaCode.selfEmplyedAndEmployed ||
        okpApproval4Watch === OkpApprovalfaCode.selfEmplyedAndEmployed ||
        okpApproval1Watch === OkpApprovalfaCode.selfEmployed ||
        okpApproval2Watch === OkpApprovalfaCode.selfEmployed ||
        okpApproval3Watch === OkpApprovalfaCode.selfEmployed ||
        okpApproval4Watch === OkpApprovalfaCode.selfEmployed;
    return (
        <>
            <Form
                size="large"
                colon={false}
                labelWrap={true}
                labelAlign="left"
                form={form}
                disabled={isUpdating}
                {...layout}
                name="admission"
                validateMessages={validateMessages}
                onValuesChange={(changedValues, values) => {
                    if (changedValues.workingtypes) {
                        if (changedValues.workingtypes.includes(WorkingType.notActive)) {
                            form.setFieldValue('workingtypes', [WorkingType.notActive]);
                        }
                    }
                }}
                initialValues={{
                    babType: babType ? babType : BabApprovalType.noBab,
                    bab1ValidFrom: bab1ValidFrom ? dayjs(bab1ValidFrom) : null,
                    bab1ValidTo: bab1ValidTo ? dayjs(bab1ValidTo) : validToValue1,
                    bab2Type: bab2Type ? bab2Type : BabApprovalType.noBab,
                    bab2ValidTo: bab2ValidTo ? dayjs(bab2ValidTo) : validToValue2,
                    bab2ValidFrom: bab2ValidFrom ? dayjs(bab2ValidFrom) : null,
                    hasAdditionalBab: hasAdditionalBab,
                    workingtypes: occupations,
                    additionalApproval: additionalApproval,
                    okpApprovalfa1Code: okpApprovalfa1Code,
                    okpApprovalfa2Code: okpApprovalfa2Code,
                    okpApprovalfa3Code: okpApprovalfa3Code,
                    okpApprovalfa4Code: okpApprovalfa4Code,
                    zsrOption: !!zsrOption ? zsrOption : !!zsr1Id ? ZsrRequiredOption.alreadyExist : zsrOption,
                    zsr1Id,
                    zsr2Id,
                    zsrRequiredReasonAsEmployeed,
                    zsrNotRequiredReason,
                }}
                onFinish={(v) => onFinish(v)}
            >
                <h2>Zulassung zur ärztlichen Tätigkeit im Kanton Zürich</h2>
                <WorkingState disabled={babReadOnly} currentWorkingTypes={workingTypesWatch} />

                <h3>Berufsausübungsbewilligung (BAB) für Kanton Zürich</h3>

                {showInfo ? (
                    <Col xl={24}>
                        <Alert
                            banner
                            action={
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.zh.ch/de/gesundheit/gesundheitsberufe/medizin.html"
                                >
                                    Link: Website des Amtes
                                </a>
                            }
                            style={{ marginBottom: '15px' }}
                            message="
                            Für eine eigenverantwortliche ärztliche Tätigkeit benötigen Sie eine Berufsausübungsbewilligung (BAB) und eine persönliche Zulassung zur OKP. 
                            Die Bewilligung und/oder Zulassung für den Kanton Zürich können Sie beim Amt für Gesundheit der Gesundheitsdirektion des Kantons Zürich beantragen.
                            Mehr Informationen und Antragsformulare finden Sie bei der Gesundheitsdirektion.
                           "
                            type="info"
                            showIcon
                        />
                    </Col>
                ) : null}

                <BabType
                    disabled={babReadOnly}
                    label="Bewilligungstyp"
                    name="babType"
                    onChange={(val) => {
                        form.setFieldValue('bab1ValidTo', calculateValidToDate('bab1', null, val));
                    }}
                />

                {!hasNoBab ? (
                    <>
                        <Form.Item rules={[{ required: true }]} name="bab1ValidFrom" label="BAB gültig von">
                            <DatePicker
                                disabled={babReadOnly}
                                onChange={(val) => {
                                    if (
                                        babTypeWatch === BabApprovalType.babFor90Days ||
                                        babTypeWatch === BabApprovalType.babFor10Years
                                    )
                                        form.setFieldValue('bab1ValidTo', calculateValidToDate('bab1', val));
                                }}
                                format={dateFormats}
                            />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: '' }]} name="bab1ValidTo" label="BAB gültig bis">
                            <DatePicker disabled={babReadOnly} format={dateFormats} />
                        </Form.Item>
                        {!noUploadRequired ? (
                            <UploadDocument
                                membershipId={id!}
                                name="uploadBab1"
                                label="Bitte laden Sie die Verfügung zu Ihrer BAB als PDF hoch!"
                                files={files}
                                fileType={FileType.Bab1}
                            />
                        ) : null}
                    </>
                ) : null}

                {hasValidOccupation && !hasNoBab ? (
                    <Form.Item
                        name="hasAdditionalBab"
                        label="Ich habe eine zweite gültige BAB für den Kanton Zürich"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                ) : null}

                {additionalBabWatch ? (
                    <>
                        <BabType
                            disabled={false}
                            label="Bewilligungstyp"
                            name="bab2Type"
                            onChange={(val) => {
                                form.setFieldValue('bab2ValidTo', calculateValidToDate('bab2', null, val));
                            }}
                        />
                        {!hasNoBab2 ? (
                            <>
                                <Form.Item name="bab2ValidFrom" label="BAB gültig ab">
                                    <DatePicker
                                        disabled={false}
                                        onChange={(val) => {
                                            if (
                                                babTypeWatch === BabApprovalType.babFor90Days ||
                                                babTypeWatch === BabApprovalType.babFor10Years
                                            )
                                                form.setFieldValue('bab2ValidTo', calculateValidToDate('bab2', val));
                                        }}
                                        format={dateFormats}
                                    />
                                </Form.Item>
                                <Form.Item name="bab2ValidTo" label="BAB gültig bis">
                                    <DatePicker disabled={false} format={dateFormats} />
                                </Form.Item>
                                <UploadDocument
                                    membershipId={id!}
                                    name="uploadBab2"
                                    label="Bitte laden Sie die Verfügung zu Ihrer zweiten BAB als PDF hoch!"
                                    fileType={FileType.Bab2}
                                    files={files}
                                />
                            </>
                        ) : null}
                    </>
                ) : null}
                {hasNoBab ? (
                    <Form.Item
                        rules={[{ required: isOnlyEmplyoeed }]}
                        name="additionalApproval"
                        label="Andere Bewilligung"
                    >
                        <Select allowClear placeholder="--Auswählen--">
                            <Option key="1" value={AdditionalApproval.assistanceApproval}>
                                Assistenzbewilligung (ausgestellt an ambulante Praxis/Institut als Arbeitgeber)
                            </Option>
                            <Option key="2" value={AdditionalApproval.employedInHospital}>
                                Anstellung in Spital (stationär)
                            </Option>
                            <Option key="3" value={AdditionalApproval.noApproval}>
                                keine Bewilligung
                            </Option>
                        </Select>
                    </Form.Item>
                ) : null}

                {!hasNoBab && !!facharztTitel1Code ? (
                    <>
                        <h3>OKP-Zulassungen im Kanton Zürich</h3>
                        <OkpApproval facharztTitelKey={facharztTitel1Code} count={1} />
                        {!!facharztTitel2Code ? <OkpApproval facharztTitelKey={facharztTitel2Code} count={2} /> : null}
                        {!!facharztTitel3Code ? <OkpApproval facharztTitelKey={facharztTitel3Code} count={3} /> : null}
                        {!!facharztTitel4Code ? <OkpApproval facharztTitelKey={facharztTitel4Code} count={4} /> : null}
                        {mustUpload ? (
                            <UploadDocument
                                membershipId={id!}
                                name="uploadOpk1"
                                label="Bitte laden Sie die Verfügung zu Ihrer OKP-Zulassung hoch"
                                files={files}
                                fileType={FileType.Okp1}
                            />
                        ) : null}
                    </>
                ) : null}

                {hasValidOccupation && !hasNoBab ? (
                    <>
                        <h3>ZSR-Nummer (Zahlstellenregisternummer) für die selbständige Abrechnung</h3>
                        <ZsrNumber
                            zsrOption={zsrOptionWatch}
                            workingType={workingTypesWatch}
                            showZsrNumber={zsrOptionWatch === ZsrRequiredOption.alreadyExist}
                        />
                    </>
                ) : null}
                {zsrOptionWatch === ZsrRequiredOption.required ? (
                    <TarmedContract membershipId={id!} crmDocuments={files} />
                ) : null}

                <NavigationButton
                    onNext={async () => {
                        await form.validateFields();
                        const values = form.getFieldsValue();
                        await onFinish(values);
                        setStep(3);
                    }}
                    onBack={() => setStep(1)}
                    showSaveButton={true}
                />
            </Form>
        </>
    );
};
