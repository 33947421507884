import { Form, Input, Select } from 'antd';
import { EntityReference, InvoiceAddressType, ZazDeliveryAddressType } from '../../../models';

type PrivateAddressProps = {
    countries: EntityReference[] | undefined;
    zazInvoiceAddressType: ZazDeliveryAddressType;
    invoiceAddressType: InvoiceAddressType;
};
export const PrivateAddress = ({ countries, zazInvoiceAddressType, invoiceAddressType }: PrivateAddressProps) => {
    const ch = countries?.find((c) => c.logicalName === 'Schweiz');
    const withoutCh = countries?.filter((c) => c.logicalName !== 'Schweiz');
    const countryOptions = [ch].concat(withoutCh)?.map((c) => {
        return { label: c?.logicalName, value: c?.id };
    });

    const inputProps: any = {};

    const addressFieldsAreRequired =
        invoiceAddressType === InvoiceAddressType.privateAddress ||
        zazInvoiceAddressType === ZazDeliveryAddressType.privateAddress;

    return (
        <>
            <h3>Privatadresse</h3>
            <Form.Item label="Adresszusatz" name="privateAddressAddition1">
                <Input />
            </Form.Item>
            <Form.Item label="Adresszusatz 2" name="privateAddressAddition2">
                <Input />
            </Form.Item>
            <Form.Item
                rules={[{ required: addressFieldsAreRequired }]}
                label="Strasse"
                name="privateAddressStreet"
                {...inputProps}
            >
                <Input />
            </Form.Item>
            <Form.Item
                rules={[{ required: addressFieldsAreRequired }]}
                label="Postleitzahl"
                name="privateAddressZipCode"
                {...inputProps}
            >
                <Input />
            </Form.Item>
            <Form.Item rules={[{ required: addressFieldsAreRequired }]} label="Ort" name="privateAddressCity">
                <Input />
            </Form.Item>
            <Form.Item
                rules={[{ required: addressFieldsAreRequired }]}
                label="Land"
                name="privateAddressCountryId"
                {...inputProps}
            >
                <Select
                    showSearch
                    placeholder="Land wählen"
                    filterOption={(input, option) => {
                        return (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase());
                    }}
                    options={countryOptions}
                />
            </Form.Item>
        </>
    );
};
